import constants from '../constants'

export default class DomainUrlKeySelector {
  GetKey(url) {
    const { CAPITALS } = constants
    const urlSplit = url.split('.')

    // Regular expression needs for cut port-number (like ":3000") from url in development mode
    const domain = urlSplit[urlSplit.length - 1].replace(/.\d+/g, '')

    switch (domain) {
      case 'ua':
      case 'lt':
      case 'lv':
      case 'ee':
      case 'pl':
      case 'by': {
        return CAPITALS[domain]
      }
      default: {
        return CAPITALS.ua
      }
    }
  }
}
