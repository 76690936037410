<template>
  <div class="dropdown-menu-city">
    <div class="dropdown-menu" @click="show = true">
      <span> {{ chosenCity }}</span>
      <i :class="show ? 'active' : ''" class="el-icon-arrow-down" />
    </div>

    <el-dialog
      :visible.sync="show"
      width="100%"
      :modal="false"
      :lock-scroll="false"
    >
      <div class="menu-header">
        <p>{{ $t('common.your_city') }}</p>
      </div>

      <div class="menu-container">
        <el-radio-group v-model="chosenCity">
          <el-radio-button
            v-for="city in cities"
            :key="city.id"
            :label="city.alternate_name"
            @click.native="selectCity(city)"
          />
        </el-radio-group>
        <p class="find-trener">{{ $t('common.find_trener_city') }}:</p>
        <AutocompleteCustom
          :type="3"
          :model="findCity"
          :placeholder="$t('placeholder.enter_city')"
          @select="handleSelect"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import constants from '@/dependencies/constants'
import PopularCitiesMixin from '@/dependencies/mixins/popular-cities'
import SetCookieMixin from '@/dependencies/mixins/set-cookie'
import AutocompleteCustom from '@/components/Common/AutocompleteCustom'

export default {
  name: 'DropdownCity',
  components: {
    AutocompleteCustom
  },
  mixins: [PopularCitiesMixin, SetCookieMixin],
  data: () => ({
    show: false,
    chosenCity: null,
    chosenCityId: null,
    findCity: null
  }),
  computed: {
    ...mapGetters({
      currentCity: 'location/getCurrentCityName'
    })
  },
  watch: {
    async chosenCityId() {
      await this.clearBeforeLocationChange()
      await Promise.all([
        this.setLocation(this.chosenCityId),
        this.loadSubwayMap(),
        this.loadSubwayLines(),
        this.loadCityDistricts()
      ])
    },
    chosenCity() {
      this.setCitySEO(this.chosenCity)
    },
    currentCity: {
      deep: true,
      handler() {
        this.chosenCity = this.currentCity
      }
    }
  },
  created() {
    this.setCapital()
  },
  methods: {
    ...mapActions({
      setLocation: 'search/SET_LOCATION',
      clearBeforeLocationChange: 'search/CLEAR_BEFORE_LOCATION_CHANGE',
      loadSubwayMap: 'search/LOAD_SUBWAY_MAP',
      loadSubwayLines: 'search/LOAD_SUBWAY_LINES',
      loadCityDistricts: 'search/LOAD_CITY_DISTRICTS',
      setCitySEO: 'seo/SET_CITY_NAME',
      setUrlKey: 'location/LOAD_URL_KEY',
      setCurrentCityName: 'location/SET_CURRENT_CITY_NAME'
    }),
    async selectCity(_city) {
      this.chosenCityId = _city.id
      this.show = false
      await this.setUrlKey(_city.url_key)
      this.setCookie('url_key', _city.url_key)
      await this.setCurrentCityName(_city.alternate_name || _city.label)
      this.locationRedirect(_city.url_key)
    },
    handleSelect(item) {
      this.findCity = null
      this.chosenCity = item.label
      this.selectCity(item)
      this.$bus.$emit('autocomplete.clear')
    },
    async setCapital() {
      const {
        CAPITALS: {
          ua: { name }
        }
      } = constants

      this.chosenCity = name
      await this.setCitySEO(name)
    },
    locationRedirect(_url) {
      const name = this.$route.name.split('___')[0]
      const { id } = this.$route.params

      this.$router.push(
        this.localePath({
          name,
          params: { location: _url, id, 0: '' }
        })
      )
    }
  }
}
</script>

<style scoped lang="scss">
@import '~assets/css/variables';

.dropdown-menu-city {
  .menu-header {
    height: 50px;
    line-height: 50px;

    p {
      font-size: 18px;
      font-weight: bold;
      color: #ffffff;
      background-color: $primary;
      text-align: center;
      text-transform: uppercase;
    }
  }
  .menu-container {
    padding: 17px 13px;
    .el-radio-group {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 20px;
    }
    .find-trener {
      color: #000000;
      font-size: 13px;
      margin-bottom: 3px;
    }
  }
}

.dropdown-menu {
  color: #808080;

  i.el-icon-arrow-down {
    transition: 0.3s ease;
    &.active {
      transform: rotate(-180deg);
      transition: 0.3s ease;
    }
  }

  &:hover {
    cursor: pointer;
  }

  span {
    font-size: 16px;
    margin-right: 2px;
  }
}

@media (min-width: 768px) {
  .dropdown-menu {
    width: 100%;
    line-height: 27px;
    font-size: 18px;

    &-city {
      .menu-header {
        display: none;
      }
      .menu-container {
        padding: 30px;

        .el-radio-group {
          margin-bottom: 17px;
          justify-content: unset;
        }

        .find-trener {
          font-size: 14px;
        }
      }
    }

    span {
      font-size: 18px;
    }
  }
}
</style>
