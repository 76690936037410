// eslint-disable-next-line import/order
import loadValidatorLocale from '@/dependencies/helpers/localize-validate'
import { extend, configure } from 'vee-validate'
import {
  required,
  email,
  min,
  max,
  alpha_num,
  min_value,
  max_value,
  integer
} from 'vee-validate/dist/rules'

const config = {
  mode: 'aggressive'
}

extend('required', required)
extend('email', email)
extend('min', min)
extend('max', max)
extend('alpha_num', alpha_num)
extend('min_value', min_value)
extend('max_value', max_value)
extend('integer', integer)

export default function({ app }) {
  loadValidatorLocale(app.i18n.locale)
}

configure(config)
