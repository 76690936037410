import { MUTATION_TRAINING_TYPES } from './mutations'

export const LOAD_TRAINING_TYPES = 'LOAD_TRAINING_TYPES'

export default {
  async [LOAD_TRAINING_TYPES]({ commit }, _id) {
    try {
      const {
        data: { data: trTypes }
      } = await this.$api.SportApi.getTrainingTypes(_id)

      commit(MUTATION_TRAINING_TYPES, trTypes)

      return Promise.resolve()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }
}
