export const SET_ERROR_MESSAGE_MUTATION = 'SET_ERROR_MESSAGE'
export const SET_FEEDBACK_BODY_MUTATION = 'SET_FEEDBACK_BODY_MUTATION'
export const SET_FEEDBACK_FILTER_MUTATION = 'SET_FEEDBACK_FILTER_MUTATION'
export const SET_FEEDBACK_RATING_MUTATION = 'SET_FEEDBACK_RATING_MUTATION'
export const RESET_FEEDBACK_DATA_MUTATION = 'RESET_FEEDBACK_DATA_MUTATION'
export const SET_WIDTH_MUTATION = 'SET_WIDTH'
export const SET_INTERNAL_SERVER_ERROR_MUTATION =
  'SET_INTERNAL_SERVER_ERROR_MUTATION'

export default {
  [SET_FEEDBACK_BODY_MUTATION](state, payload) {
    state.feedbackData.text = payload
  },

  [SET_FEEDBACK_FILTER_MUTATION](state, payload) {
    state.feedbackData.filter = payload
  },

  [SET_FEEDBACK_RATING_MUTATION](state, payload) {
    state.feedbackData.rating = payload
  },

  [RESET_FEEDBACK_DATA_MUTATION](state) {
    state.feedbackData.text = ''
    state.feedbackData.rating = null
    state.feedbackData.filter = null
  },

  [SET_ERROR_MESSAGE_MUTATION](state, payload) {
    state.error = payload
  },

  [SET_WIDTH_MUTATION](state, width) {
    state.isMobile = width
  },

  [SET_INTERNAL_SERVER_ERROR_MUTATION](state, payload) {
    state.internalServError = payload
  }
}
