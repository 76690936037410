export default async function({ route, store, next }) {
  const CACHED_PATHS = [
    'location-search-trainers-all',
    'location-trainer-id',
    'location-club-id'
  ]
  //  clear cache if path doesnt match any template in CACHED_PATHS
  if (route.name) {
    const clearCached = CACHED_PATHS.every((item) => !route.name.includes(item))
    if (clearCached) {
      await Promise.all([
        store.dispatch('search/CACHE_TRAINERS', null),
        store.dispatch('search/CLEAR_FILTERS')
      ])
    }
  } else {
    next()
  }
}
