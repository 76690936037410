import DomainUrlKeySelector from '../dependencies/helpers/domain-url-key-selector'

export default function(req, res, next) {
  const { url_key, langPrefix } = new DomainUrlKeySelector().GetKey(
    req.headers.host
  )

  const matchLangPrefix = (_url) => {
    return _url === `/${langPrefix}` || _url === `/${langPrefix}/`
  }
  if (req.url === '/') {
    if (langPrefix) {
      res.writeHead(301, { Location: `/${langPrefix}/${url_key}` })
      return res.end()
    }
  } else if (matchLangPrefix(req.url)) {
    res.writeHead(301, { Location: `/${langPrefix}/${url_key}` })
    return res.end()
  } else {
    next()
  }
}
