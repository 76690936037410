import constants from '@/dependencies/constants'
import BaseApi from '@/api/base'

export default class PublicApi extends BaseApi {
  getTrainerInfo({ _id, config }) {
    return this.$axios.get(
      constants.API.ROUTES.PUBLIC.TRAINER_INFO(_id),
      config
    )
  }

  getTrainerFeedbacks(_id) {
    return this.$axios.get(
      constants.API.ROUTES.PUBLIC.TRAINER_FEEDBACK_MSGS(_id)
    )
  }

  getSportClubInfo({ _id, config }) {
    return this.$axios.get(
      constants.API.ROUTES.PUBLIC.SPORT_CLUB_INFO(_id),
      config
    )
  }

  postComment({ _id, params }) {
    return this.$axios.post(
      constants.API.ROUTES.COMMENT.POST_COMMENT(_id),
      params
    )
  }

  sendTrainerMessage(params) {
    return this.$axios.post(
      constants.API.ROUTES.PUBLIC.SEND_MESSAGE_TO_TRAINER,
      params
    )
  }

  getOpenContacts(id, recaptcha) {
    return this.$axios.get(
      constants.API.ROUTES.PUBLIC.GET_OPEN_CONTACTS(id, recaptcha)
    )
  }
}
