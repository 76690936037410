export default function({ store, app, route }) {
  const {
    params: { location }
  } = route

  window.onNuxtReady(async () => {
    app.$cookies.set('locale', app.i18n.locale)
    app.$cookies.set('url_key', location)

    try {
      await Promise.all([
        store.dispatch('location/SET_DOMAIN_LANG'),
        store.dispatch('user/LOAD_DOMAIN_INFORMATION'),
        store.dispatch('location/LOAD_CITY', { title: location }),
        store.dispatch('seo/SET_DOMAIN_TITLE'),
        store.dispatch('dict/LOAD_CLASSIFIERS')
      ])
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  })
}
