export default {
  watch: {
    show() {
      this.show ? this.styleToggler('hidden') : this.styleToggler('unset')
    }
  },
  methods: {
    styleToggler(_overflow) {
      document.documentElement.style.overflow = _overflow
    }
  }
}
