import { mapActions, mapGetters } from 'vuex'
import socNetwork from '@/dependencies/helpers/social-network-selector'
import { FACEBOOK_APP_ID, INSTAGRAM_CLIENT_ID } from '@/config/socials'
import { Facebook } from '@/dependencies/helpers/facebook'

export default {
  computed: {
    ...mapGetters({
      socNetworks: 'dict/getSocialNetworks'
    })
  },
  methods: {
    ...mapActions({
      setSocAuth: 'auth/SET_USER_SOC_AUTH'
    }),
    async loginWithFacebook() {
      const { id } = socNetwork(this.socNetworks, 'Facebook')
      const FB = new Facebook()

      await FB.init({
        appId: FACEBOOK_APP_ID,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v5.0'
      })

      let token

      try {
        const {
          authResponse: { accessToken }
        } = await FB.login()
        token = accessToken
      } catch (e) {
        return
      }

      const {
        picture: {
          data: { url }
        },
        first_name,
        last_name,
        email
      } = await FB.me()

      const authObj = Object.assign(
        {},
        { access_token: token },
        { first_name },
        { last_name },
        { avatar: url },
        { network_id: id },
        { email }
      )

      await this.setSocAuth(authObj)
    },

    async loginWithGoogle() {
      const { id } = socNetwork(this.socNetworks, 'Google+')
      const { google_auth2: auth2 } = window

      try {
        const resData = await auth2.signIn()
        const user = resData.getBasicProfile()

        const getAccessToken = () => {
          for (const key in resData) {
            if (resData[key]?.access_token) {
              return resData[key].access_token
            }
          }
        }

        const authObj = Object.assign(
          {},
          { access_token: getAccessToken() },
          { first_name: user.getGivenName() },
          { last_name: user.getFamilyName() },
          { avatar: user.getImageUrl() },
          { network_id: id },
          { email: user.getEmail() }
        )

        await this.setSocAuth(authObj)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    },

    loginWithInstagram(_platform) {
      return new Promise((resolve) => {
        const width = 700
        const height = 600
        const left = (window.screen.width - width) / 2
        const top = (window.screen.height - height) / 2
        const features = `width=${width},height=${height},left=${left},top=${top}`

        const windowCreate = (_target, _features = '') =>
          window.open(
            'https://api.instagram.com/oauth/authorize/?client_id=' +
              INSTAGRAM_CLIENT_ID +
              '&redirect_uri=' +
              `${origin}/profile/` +
              '&response_type=token',
            _target,
            `${_features}`
          )
        if (_platform) {
          const popup = windowCreate('_blank', features)

          popup.onload = () => {
            const interval = setInterval(() => {
              const { hash } = popup.location
              try {
                if (hash.length) {
                  clearInterval(interval)
                  const accessToken = hash.slice(14)
                  resolve(accessToken)
                  popup.close()
                }
              } catch (e) {
                // eslint-disable-next-line no-console
                console.log(e)
              }
            }, 100)
          }
        } else {
          windowCreate('_self')
        }
      })
    }
  }
}
