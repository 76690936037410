<!-- @TODO заменить дизайн, когда будет макет -->
<template>
  <div class="error-page">
    <p class="text">
      Внимание! Проводятся технические работы.<br />
      Сервис будет доступен в ближайшее время.
    </p>
  </div>
</template>

<script>
export default {
  name: 'InternalServerError'
}
</script>

<style lang="scss" scoped>
@import '~assets/css/variables';

.error-page {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .text {
    text-align: center;
    width: 90%;
    color: $red;
    font-size: 25px;
    line-height: 40px;
    font-weight: bold;
  }
}
@media (min-width: 768px) {
  .error-page {
    .text {
      width: 60%;
      font-size: 35px;
      line-height: 50px;
    }
  }
}
</style>
