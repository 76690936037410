import constants from '@/dependencies/constants'
import BaseApi from '@/api/base'

export default class SportApi extends BaseApi {
  allSports() {
    return this.$axios.get(constants.API.ROUTES.SPORT.ALL_SPORTS)
  }

  getSportsClubsList(params) {
    return this.$axios.get(constants.API.ROUTES.SPORT.CLUBS_SPORTS, params)
  }

  getTrainingTypes(_id) {
    return this.$axios.get(constants.API.ROUTES.SPORTS.TRAINING_TYPES(_id))
  }
}
