<template>
  <ValidationObserver ref="observer">
    <form ref="form" class="register-form" @keypress.13.stop="registerUser">
      <div class="form-line email">
        <i class="icon-email-lined" />
        <Input
          v-model="email"
          :is-validate="true"
          rules="required|email"
          name="email"
          type="email"
          clearable
          :readonly="readonly"
          :placeholder="$t('placeholder.your_email')"
          @click.native="readonly = false"
        />
      </div>
      <div class="form-line name">
        <i class="icon-user-lined" />
        <Input
          v-model="firstName"
          :is-validate="true"
          rules="required|min:2"
          name="name"
          type="text"
          :placeholder="$t('placeholder.firstname')"
          clearable
        />
        <Input
          v-model="lastName"
          :is-validate="true"
          rules="required|min:2"
          name="surname"
          type="text"
          :placeholder="$t('placeholder.lastname')"
          clearable
        />
      </div>
      <div class="form-line">
        <i class="icon-ball-lined" />
        <div class="sport-selector">
          <ValidationProvider
            v-slot="{ errors }"
            rules="required"
            :name="$t('common.kind_of_sport')"
          >
            <div class="flex-pos">
              <span v-if="errors" class="error-message">{{ errors[0] }}</span>
            </div>
            <el-select
              v-model="sport"
              class="sport-types"
              :class="{ selected: sport, invalid: errors[0] }"
              clearable
              :popper-append-to-body="false"
              :placeholder="$t('placeholder.main_sport')"
            >
              <el-option
                v-for="sportItem in allSports"
                :key="sportItem.id"
                :value="sportItem.id"
                :label="sportItem.value"
              />
            </el-select>
          </ValidationProvider>
        </div>
      </div>
      <div class="description">
        <p>{{ $t('common.reg_input_info') }}</p>
      </div>
      <div class="phone-block form-line">
        <i class="icon-phone-lined" />
        <p class="country-code">
          {{ phoneMask.prefix }}
        </p>
        <Input
          v-model="phone"
          class="phone-input"
          rules="required"
          :is-validate="true"
          :mask="phoneMask.mask"
          name="phone"
          type="phone"
          :placeholder="phoneMask.placeholder"
          clearable
        />
      </div>
      <div class="form-line">
        <i class="icon-password-lined" />
        <Input
          v-model="passwordOne"
          rules="required|min:6"
          :is-validate="true"
          name="password"
          type="password"
          :placeholder="$t('placeholder.password')"
          clearable
        />
      </div>
      <div class="form-line">
        <i class="icon-password-lined" />
        <Input
          v-model="passwordTwo"
          rules="required|min:6"
          :is-validate="true"
          name="password2"
          type="password"
          :placeholder="$t('placeholder.password_confirm')"
          clearable
        />
      </div>
      <div v-if="!isLoading" class="buttons-block">
        <el-button
          type="warning"
          class="register"
          @click.prevent="registerUser"
        >
          {{ $t('common.registrate') }}
        </el-button>
        <div class="d-flex align-items-center flex-column">
          <p class="my-2">
            {{ $t('common.or_enter_via') }}
          </p>
          <div class="social-buttons">
            <button v-if="!hideSocialLogin" class="fb-button" @click.prevent="loginWithFacebook" />
            <button class="google-button" @click.prevent="loginWithGoogle" />
          </div>
        </div>
      </div>
      <div v-else class="loader-container">
        <i class="el-icon-loading" />
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Input from '@/components/Common/Input'
import SocialAuthMixin from '@/dependencies/mixins/social-auth'
import DomainSelector from '@/dependencies/helpers/domain-selector'

export default {
  name: 'Registration',
  components: {
    Input,
    ValidationObserver,
    ValidationProvider
  },
  mixins: [SocialAuthMixin],
  props: {
    profileRedirect: {
      type: Function,
      default: () => 1
    }
  },
  data: () => ({
    email: null,
    firstName: null,
    lastName: null,
    sport: null,
    phone: null,
    passwordOne: null,
    passwordTwo: null,
    isLoading: false,
    readonly: true,
    hideSocialLogin: false,
  }),
  computed: {
    ...mapGetters({
      phoneMask: 'user/getMask',
      allSports: 'dict/getAllSports',
      socialAuth: 'auth/socAuth',
      city: 'location/getUrlKey'
    })
  },
  mounted() {
    this.hideSocialLogin = new DomainSelector().isPLDomain(window.location.hostname)
  },
  watch: {
    socialAuth() {
      this.setSocialData()
    }
  },
  methods: {
    ...mapActions({
      setError: 'common/SET_ERROR_MESSAGE'
    }),

    setSocialData() {
      if (!this.socialAuth) return
      const { email, first_name, last_name } = this.socialAuth

      this.email = email
      this.firstName = first_name
      this.lastName = last_name
    },

    async registerUser() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) return

      this.isLoading = true

      const baseParams = {
        email: this.email,
        first_name: this.firstName,
        last_name: this.lastName,
        sport_id: this.sport,
        phone: this.phone,
        password: this.passwordOne,
        password_confirmation: this.passwordTwo
      }

      let socialParams
      if (this.socialAuth) {
        const { access_token, network_id: social_id } = this.socialAuth
        socialParams = {
          social_id,
          access_token
        }
      }

      const params = this.socialAuth
        ? Object.assign({}, baseParams, socialParams)
        : baseParams

      try {
        const {
          data: {
            data: { access_token: token }
          }
        } = await this.$api.AuthApi.register(params)
        await Promise.all([
          this.$auth.setUserToken(token),
          this.$auth.fetchUser()
        ])
        this.isLoading = false
        this.setSocAuth(null)
        this.profileRedirect()
        this.$bus.$emit('closeRegModal', 250)
        this.$refs.observer.reset()
        this.formReset()
      } catch (e) {
        this.isLoading = false
        const {
          response: {
            data: { errors }
          }
        } = e

        await this.setError(errors)
        // eslint-disable-next-line no-console
        console.log(e)
      }
    },

    formReset() {
      this.email = this.firstName = this.lastName = this.sport = this.phone = this.passwordOne = this.passwordTwo = null
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~assets/css/variables';

.sport-types {
  width: 100% !important;
  height: 40px;
  margin-bottom: 5px;
  color: #a6a6a6;
  font-size: 18px;

  &.selected {
    color: #000000;
  }
  option {
    color: #000000;
  }
}

.description {
  font-size: 13px;
  color: #808080;
  margin-bottom: 10px;
  p {
    word-break: normal !important;
  }
}

.social-buttons {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.register-form {
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .form-line {
    display: flex;
    justify-content: space-between;
    position: relative;

    &.name {
      .input-container {
        width: 48%;
      }
    }

    .input-container {
      width: 100%;
    }

    i {
      font-size: 24px;
      color: $primary;
      position: absolute;
      left: -35px;
      top: 22px;
    }

    .sport-selector {
      width: 100%;

      .flex-pos {
        height: 14px;
        line-height: 13px;
      }
    }
  }

  .buttons-block {
    margin-top: 24px;
  }
}

.loader-container {
  margin-top: 24px;
  display: flex;
  align-items: center;
  min-height: 115px;
  padding: 0 42%;

  i {
    font-size: 35px;
    color: $primary;
  }
}

.register {
  width: 100%;
  font-size: 18px !important;
  background-color: #f78e25 !important;
}

.phone-block {
  display: flex;
  justify-content: space-between;

  .phone-input {
    width: 79% !important;
  }

  .country-code {
    width: 17%;
    height: 40px;
    border-radius: 3px;
    border: 1px solid rgba(128, 128, 128, 0.6);
    font-size: 18px;
    color: #a6a6a6;
    line-height: 38px;
    margin-top: 14px;
    text-align: center;
  }
}
@media (min-width: 768px) {
  .buttons-block {
    button {
      transition: 0.2s linear;

      &:hover {
        transition: 0.2s linear;
        box-shadow: 0 1px 9px rgba(0, 0, 0, 0.4);
      }
    }
  }
}
</style>
