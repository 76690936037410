import {
  SET_DOMAIN_TITLE_MUTATION,
  SET_CITY_NAME_MUTATION,
  SET_PAGE_META_MUTATION,
  SET_PAGE_TITLE_MUTATION
} from './mutations'
import DomainSelector from '@/dependencies/helpers/domain-selector'

export const SET_DOMAIN_TITLE_ACTION = 'SET_DOMAIN_TITLE'
export const SET_CITY_NAME_ACTION = 'SET_CITY_NAME'
export const SET_PAGE_META_ACTION = 'SET_PAGE_META_ACTION'
export const SET_PAGE_TITLE_ACTION = 'SET_PAGE_TITLE_ACTION'

export default {
  [SET_DOMAIN_TITLE_ACTION]({ commit }) {
    const { title } = new DomainSelector.GetDomain(window.location.hostname)
    commit(SET_DOMAIN_TITLE_MUTATION, title)
    return Promise.resolve()
  },

  [SET_CITY_NAME_ACTION]({ commit }, payload) {
    commit(SET_CITY_NAME_MUTATION, payload)
    return Promise.resolve()
  },

  [SET_PAGE_META_ACTION]({ commit }, meta) {
    commit(SET_PAGE_META_MUTATION, meta)
    return Promise.resolve()
  },

  [SET_PAGE_TITLE_ACTION]({ commit }, title) {
    commit(SET_PAGE_TITLE_MUTATION, title)
    return Promise.resolve()
  }
}
