<template>
  <div id="header" class="header-wrapper" :class="{ shadow: matchURL }">
    <header class="header">
      <div class="left-side">
        <div class="logo" @click="goHome" />
        <span class="title-city">{{ $t('common.your_city') }}:</span>
        <DropdownCity />
        <span class="title-lang">{{ $t('common.language') }}:</span>
        <DropdownLang />
      </div>
      <div class="right-side">
        <template v-if="isLogged">
          <BurgerMenu :logout="logout" />
          <button class="title-exit" @click="logout()">
            {{ $t('common.exit') }}
          </button>
          <img class="avatar-image" :src="userAvatar" alt="ava-image" />
          <nuxt-link
            class="user-name"
            :to="
              localePath({
                name: 'location-profile-index',
                params: { location: city }
              })
            "
          >
            {{ userName }}
          </nuxt-link>
        </template>
        <template v-else>
          <button class="login-btn" @click="login()">
            <i class="icon-user" />
            <span class="title-enter">{{ $t('common.enter') }}</span>
          </button>
          <button class="register-btn add-trainer" @click="login(true)">
            <span class="add-trainer-icon" />
            {{ $t('common.register_trener') }}
          </button>
        </template>
      </div>
    </header>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DropdownCity from '@/components/Dropdowns/DropdownCity'
import DropdownLang from '@/components/Dropdowns/DropdownLang'
import BurgerMenu from '@/components/BurgerMenu'
import LogoutRedirectMixin from '@/dependencies/mixins/profile/logout-redirect'

export default {
  name: 'Header',
  components: { DropdownCity, DropdownLang, BurgerMenu },
  mixins: [LogoutRedirectMixin],
  data: () => ({
    dialog: false,
    matchURL: false
  }),
  computed: {
    ...mapGetters({
      city: 'location/getUrlKey'
    }),
    userName() {
      const { first_name, last_name } = this.user
      return `${first_name} ${last_name}`
    },
    userAvatar() {
      return this.user.avatar
    },
    user() {
      return this.$auth.user
    }
  },
  watch: {
    $route() {
      this.getURL()
    }
  },
  mounted() {
    this.getURL()
  },
  methods: {
    logout() {
      this.$auth.logout()
      if (!this.isLogged) this.goHome()
    },
    login(_reg = false) {
      this.$bus.$emit('openLogin', _reg)
    },
    getURL() {
      const splitURL = this.$route.path.split('/')
      this.matchURL = splitURL[splitURL.length - 2] === 'profile'
    }
  }
}
</script>

<style scoped lang="scss">
@import '~assets/css/variables';

.header-wrapper {
  background-color: #ffffff;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  @include bottom-light-shadow();

  &.shadow {
    z-index: 11;
    box-shadow: none;
    border-bottom: 1px solid $gray-active;
  }
}

header {
  min-width: 320px;
  max-width: 1200px;
  margin: 0 auto;
  height: 50px;
  padding: 10px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;

  .left-side,
  .right-side {
    display: flex;
    align-items: center;
  }
  .left-side {
    .title-city,
    .title-lang {
      display: none;
      font-size: 18px;
      font-weight: 400;
      color: #808080;
      margin-right: 10px;
    }
    .title-lang {
      margin-right: 5px !important;
    }
  }
  .right-side {
    .title-enter,
    .title-exit {
      display: none;
    }
    .title-exit {
      border-right: 1px solid #c4c4c4;
    }
    .avatar-image {
      display: none;
      margin: 0 10px 0 20px;
      width: 28px;
      height: 28px;
      border-radius: 2px;
    }
    .user-name {
      font-size: 18px;
      color: $primary;
      display: none;
    }
  }
}

.logo {
  display: block;
  max-width: 43px;
  width: 43px;
  height: 30px;
  background: url('../assets/img/logo-desktop.png') no-repeat center center;
  background-size: cover;
  margin-right: 16px;

  &:hover {
    cursor: pointer;
  }
}

.login-btn {
  display: flex;
  align-items: center;
  i {
    color: $gray;
    font-size: 22px;
    line-height: 22px;
  }
}

@media (min-width: 768px) {
  .header-wrapper,
  .header-wrapper.shadow {
    border-bottom: none;
    @include bottom-light-shadow();
  }

  header {
    height: 80px;

    .left-side {
      .title-city,
      .title-lang {
        border-left: 1px solid #c4c4c4;
        padding-left: 20px;
        line-height: 27px;
        display: block;
      }
      .title-lang {
        margin-left: 25px;
      }
    }
    .right-side {
      .title-enter,
      .title-exit {
        padding: 0 20px 0 10px;
        font-size: 18px;
        display: block;
        color: #808080;

        &:hover {
          cursor: pointer;
        }
      }
      .add-trainer {
        display: flex;
      }
      .avatar-image {
        display: block;
      }
      .user-name {
        display: block;
      }
    }
  }
  .logo {
    max-width: 67px;
    width: 67px;
    height: 48px;
    margin-right: 20px;
  }
}

@media (min-width: 1200px) {
  .header {
    .left-side {
      .title-lang {
        margin-left: 125px;
      }
    }
  }
}

.add-trainer {
  display: none;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 0 20px;
  border: 1px solid #6bacb0;
  border-radius: 3px;
  font-size: 18px;
  color: $primary;
  transition: 0.2s ease-out;
  .add-trainer-icon {
    width: 26px;
    min-width: 26px;
    height: 26px;
    background-image: url('~assets/img/plus-icon.png');
    margin-right: 15px;
  }
  &:hover {
    background-color: $primary;
    transition: 0.2s linear;
    color: white;
    .add-trainer-icon {
      background-image: url('~assets/img/plus-icon-hover.png');
    }
  }
}
</style>
