// eslint-disable-next-line import/order
import isEqual from 'lodash.isequal'
import {
  LOAD_TRAINERS_LIST,
  LOAD_META,
  CHANGE_CURRENT_PAGE,
  SET_SORT_METHOD,
  ADD_BOOKMARK,
  DELETE_BOOKMARK,
  SET_SPORT_TYPE,
  SET_SPORT_CLUB,
  SET_SUBWAY,
  SET_GENDER,
  SET_AGE,
  SET_COST,
  SET_SCHEDULE,
  SET_OUTDOOR,
  CHANGE_LOCATION,
  LOAD_SEO,
  SHOW_BOOKMARKS,
  LOAD_SUBWAY_STATIONS,
  LOAD_SUBWAY_LINES,
  LOAD_SUBWAY_MAP_MUTATION,
  RESET_FILTERS,
  CLEAR_FILTERS_MUTATION,
  LOAD_CLUBS_LIST,
  SCHEDULE_STR,
  CLEAR_BEFORE_NEW_QUERY,
  CLEAR_BEFORE_LOCATION_CHANGE,
  LOAD_CITY_DISTRICTS_MUTATION,
  SET_DISTRICTS_CLEAR,
  SET_ALL_DISTRICTS,
  EMPTY_TRAINERS_LIST_MUTATION,
  CACHE_TRAINERS_MUTATION,
  FOCUS_TRAINER_MUTATION,
  FILL_DISTRICTS_NAMES_MUTATION,
  CHANGE_FILTERS_STATUS_MUTATION,
  CHANGE_REQUEST_STATUS_MUTATION
} from './mutations'
import SubwayMapSelector from '@/dependencies/helpers/subway-map-selector'

export const ACTION_LOAD_TRAINERS_LIST = 'LOAD_TRAINERS_LIST'
export const ACTION_SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'
export const ACTION_SET_SORT_METHOD = 'SET_SORT_METHOD'
export const ACTION_ADD_BOOKMARK = 'ADD_BOOKMARK'
export const ACTION_DELETE_BOOKMARK = 'DELETE_BOOKMARK'
export const ACTION_SET_SPORT_TYPE = 'SET_SPORT_TYPE'
export const ACTION_SET_SPORT_CLUB = 'SET_SPORT_CLUB'
export const ACTION_SET_SUBWAY = 'SET_SUBWAY'
export const ACTION_SET_GENDER = 'SET_GENDER'
export const ACTION_SET_AGE = 'SET_AGE'
export const ACTION_SET_COST = 'SET_COST'
export const ACTION_SET_SCHEDULE = 'SET_SCHEDULE'
export const ACTION_SET_OUTDOOR = 'SET_OUTDOOR'
export const ACTION_CLEAR_BEFORE_LOCATION_CHANGE =
  'CLEAR_BEFORE_LOCATION_CHANGE'
export const ACTION_SET_LOCATION = 'SET_LOCATION'
export const ACTION_SHOW_BOOKMARKS = 'SHOW_BOOKMARKS'
export const ACTION_RESET_FILTERS = 'RESET_FILTERS'
export const ACTION_LOAD_SUBWAY_LINES = 'LOAD_SUBWAY_LINES'
export const ACTION_LOAD_SUBWAY_STATIONS = 'LOAD_SUBWAY_STATIONS'
export const LOAD_SUBWAY_MAP_ACTION = 'LOAD_SUBWAY_MAP'
export const LOAD_CITY_DISTRICTS_ACTION = 'LOAD_CITY_DISTRICTS'
export const DEFAULT_INPUT_RESET_ACTION = 'DEFAULT_INPUT_RESET_ACTION'
export const CLEAR_DISTRICTS_ACTION = 'CLEAR_DISTRICTS'
export const ACTION_SET_ALL_DISTRICTS = 'SET_ALL_DISTRICTS'
export const ACTION_LOAD_TRAINERS_CLUBS = 'LOAD_TRAINERS_CLUBS'
export const ACTION_CACHE_TRAINERS = 'CACHE_TRAINERS'
export const ACTION_FOCUS_TRAINER = 'FOCUS_TRAINER'
export const ACTION_LOAD_CLUB = 'LOAD_CLUB'
export const ACTION_FILL_DISTRICTS_NAMES = 'FILL_DISTRICTS_NAMES'
export const SET_RESET_FILTERS_STATUS_ACTION = 'SET_RESET_FILTERS_STATUS'
export const SET_REQUEST_STATUS_ACTION = 'SET_REQUEST_STATUS'
export const CLEAR_BEFORE_NEW_QUERY_ACTION = 'CLEAR_BEFORE_NEW_QUERY'
export const CLEAR_FILTERS_ACTION = 'CLEAR_FILTERS'

export default {
  async [ACTION_LOAD_TRAINERS_LIST]({
    commit,
    dispatch,
    state,
    rootState,
    getters
  }) {
    const { location } = state

    //  clear trainersList, seo, meta and clubsList
    //  before new query (seo and meta only on desktop)
    const isMobile = rootState.common.isMobile
    commit(CLEAR_BEFORE_NEW_QUERY, isMobile)

    if (!location) return

    await dispatch(SET_REQUEST_STATUS_ACTION, true)

    try {
      const searchQueries = getters.getQueryParameters

      const {
        data: {
          data: trainersList,
          meta,
          seo: { h1: responseHeader, meta: responseMeta, title: responseTitle }
        }
      } = await this.$api.UserApi.getTrainersList(searchQueries)

      if (!trainersList.length) {
        commit(EMPTY_TRAINERS_LIST_MUTATION, true)
      }

      commit(LOAD_META, meta)
      commit(LOAD_SEO, responseHeader)

      await Promise.all([
        dispatch('seo/SET_PAGE_META_ACTION', responseMeta, { root: true }),
        dispatch('seo/SET_PAGE_TITLE_ACTION', responseTitle, { root: true })
      ])

      commit(LOAD_TRAINERS_LIST, trainersList)

      await dispatch(SET_REQUEST_STATUS_ACTION, false)
    } catch (err) {
      await dispatch(SET_REQUEST_STATUS_ACTION, false)
      // eslint-disable-next-line no-console
      console.error(err)
    }
  },
  [ACTION_LOAD_CLUB]({ commit, dispatch }, clubID) {
    return this.$api.PublicApi.getSportClubInfo({
      _id: clubID
    }).then((response) => {
      return response.data
    })
  },
  async [ACTION_LOAD_TRAINERS_CLUBS]({ commit, getters }) {
    try {
      const searchQueries = getters.getQueryParameters

      const {
        data: { data: clubsList }
      } = await this.$api.UserApi.getTrainersClubs(searchQueries)

      commit(LOAD_CLUBS_LIST, clubsList)
      return Promise.resolve()
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
    }
  },
  [ACTION_SET_CURRENT_PAGE]({ commit }, currentPage) {
    commit(CHANGE_CURRENT_PAGE, currentPage)
    return Promise.resolve()
  },
  [ACTION_SET_SORT_METHOD]({ commit, state }, sortMethod) {
    if (!isEqual(state.sortMethod, sortMethod)) {
      commit(SET_SORT_METHOD, sortMethod)
    }

    return Promise.resolve()
  },
  [ACTION_ADD_BOOKMARK]({ commit }, bookmark) {
    commit(ADD_BOOKMARK, bookmark)
    return Promise.resolve()
  },
  [ACTION_DELETE_BOOKMARK]({ commit, state }, bookmarkID) {
    if (state.bookmarksNumber) {
      commit(DELETE_BOOKMARK, bookmarkID)
    }
    return Promise.resolve()
  },
  [ACTION_SET_SPORT_TYPE]({ commit, state }, sportType) {
    if (state.sportType?.id !== sportType?.id) {
      commit(SET_SPORT_TYPE, sportType)
    }
    return Promise.resolve()
  },
  [ACTION_SET_SPORT_CLUB]({ commit, state }, sportClub) {
    if (state.sportClub?.id !== sportClub?.id) {
      commit(SET_SPORT_CLUB, sportClub)
    }
    return Promise.resolve()
  },
  [ACTION_SET_ALL_DISTRICTS]({ commit, state }, districtsObj) {
    const { districts, microdistricts } = districtsObj
    if (
      !isEqual(
        districts.map(({ id }) => id),
        state.searchDistr.map(({ id }) => id)
      ) ||
      !isEqual(
        microdistricts.map(({ id }) => id),
        state.searchMicroDistrict.map(({ id }) => id)
      )
    ) {
      commit(CACHE_TRAINERS_MUTATION, null)
      commit(SET_ALL_DISTRICTS, districtsObj)
    }

    return Promise.resolve()
  },
  [CLEAR_DISTRICTS_ACTION]({ commit }) {
    commit(SET_DISTRICTS_CLEAR)
    return Promise.resolve()
  },
  [ACTION_FILL_DISTRICTS_NAMES]({ commit }) {
    commit(FILL_DISTRICTS_NAMES_MUTATION)
    return Promise.resolve()
  },
  [ACTION_SET_SUBWAY]({ commit, state }, subway) {
    if (!isEqual(state.searchSubway, subway)) {
      commit(CACHE_TRAINERS_MUTATION, null)
      commit(SET_SUBWAY, subway)
    }
    return Promise.resolve()
  },
  [ACTION_SET_GENDER]({ commit, state }, gender) {
    if (state.searchGender !== gender) {
      commit(SET_GENDER, gender)
    }
    return Promise.resolve()
  },
  [ACTION_SET_AGE]({ commit, state }, age) {
    if (!isEqual(state.searchAge, age)) {
      commit(SET_AGE, age)
    }
    return Promise.resolve()
  },
  [ACTION_SET_COST]({ commit, state }, cost) {
    if (!isEqual(state.searchCost, cost)) {
      commit(SET_COST, cost)
    }
    return Promise.resolve()
  },
  [ACTION_SET_SCHEDULE]({ commit, state }, { schedule, scheduleStr }) {
    if (!isEqual(state.searchSchedule, schedule)) {
      commit(SET_SCHEDULE, schedule)
      commit(SCHEDULE_STR, scheduleStr)
    }
    return Promise.resolve()
  },
  [ACTION_SET_OUTDOOR]({ commit, state }, outdoor) {
    if (state.outdoor !== outdoor) {
      commit(SET_OUTDOOR, outdoor)
    }
    return Promise.resolve()
  },
  [ACTION_SET_LOCATION]({ dispatch, commit }, location) {
    if (location) {
      commit(CHANGE_LOCATION, location)
    }
    return Promise.resolve()
  },
  [ACTION_CLEAR_BEFORE_LOCATION_CHANGE]({ commit }) {
    commit(CLEAR_BEFORE_LOCATION_CHANGE)
  },
  [ACTION_SHOW_BOOKMARKS]({ commit }, bool) {
    commit(SHOW_BOOKMARKS, bool)
    return Promise.resolve()
  },

  async [ACTION_RESET_FILTERS]({ commit, dispatch }, payload) {
    if (payload) {
      await dispatch(SET_RESET_FILTERS_STATUS_ACTION, payload)
    }
    commit(RESET_FILTERS)
    return Promise.resolve()
  },

  async [CLEAR_FILTERS_ACTION]({ commit, dispatch }) {
    await dispatch(SET_RESET_FILTERS_STATUS_ACTION, true)
    commit(CLEAR_FILTERS_MUTATION)
    return Promise.resolve()
  },

  async [ACTION_LOAD_SUBWAY_LINES]({ dispatch, commit, state }) {
    const { location } = state

    if (!location) return
    try {
      const {
        data: { data: subwayLines }
      } = await this.$api.LocationApi.getSubwayLines({
        params: {
          city_id: location
        }
      })
      commit(LOAD_SUBWAY_LINES, subwayLines)

      Promise.all([
        subwayLines.map((line) => {
          dispatch(ACTION_LOAD_SUBWAY_STATIONS, line.id)
        })
      ])

      return Promise.resolve()
    } catch (err) {
      //  eslint-disable-next-line
      console.log(err)
    }
  },

  async [ACTION_LOAD_SUBWAY_STATIONS]({ commit, state }, _lineID) {
    try {
      const {
        data: { data: stations }
      } = await this.$api.LocationApi.getSubwayStations({
        params: {
          line_id: _lineID
        }
      })
      commit(LOAD_SUBWAY_STATIONS, { stations, lineID: _lineID })

      return Promise.resolve()
    } catch (err) {
      //  eslint-disable-next-line
      console.error(err)
    }
  },

  [LOAD_SUBWAY_MAP_ACTION]({ commit, state }) {
    const { location } = state

    const map = SubwayMapSelector.GetMap(location)
    commit(LOAD_SUBWAY_MAP_MUTATION, map)

    return Promise.resolve()
  },

  async [LOAD_CITY_DISTRICTS_ACTION]({ commit, state }) {
    const { location } = state
    commit(LOAD_CITY_DISTRICTS_MUTATION, [])

    try {
      const {
        data: { data: districts }
      } = await this.$api.LocationApi.getCityDistricts({
        params: {
          cityId: location
        }
      })

      commit(LOAD_CITY_DISTRICTS_MUTATION, districts)

      return Promise.resolve()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  },
  [DEFAULT_INPUT_RESET_ACTION]() {
    //  this action is necessary to be stub
    //  if no action name was passed to InputFilter
    return Promise.resolve()
  },
  async [ACTION_CACHE_TRAINERS]({ commit, dispatch }, trainers) {
    await commit(CACHE_TRAINERS_MUTATION, trainers)
  },
  [ACTION_FOCUS_TRAINER]({ commit }, focusedID) {
    commit(FOCUS_TRAINER_MUTATION, focusedID)
    return Promise.resolve()
  },

  [SET_RESET_FILTERS_STATUS_ACTION]({ commit }, payload = false) {
    commit(CHANGE_FILTERS_STATUS_MUTATION, payload)
    return Promise.resolve()
  },

  [SET_REQUEST_STATUS_ACTION]({ commit }, payload) {
    commit(CHANGE_REQUEST_STATUS_MUTATION, payload)
    return Promise.resolve()
  },

  [CLEAR_BEFORE_NEW_QUERY_ACTION]({ commit }, isMobile) {
    commit(CLEAR_BEFORE_NEW_QUERY, isMobile)
    return Promise.resolve()
  }
}
