import {
  SET_USER_DATA_MUTATION,
  SET_USER_ACHIEVEMENTS_MUTATION,
  SET_USER_CONTACTS_MUTATION,
  SET_USER_AVATAR_MUTATION,
  SET_USER_WORKPLACES_MUTATION,
  USER_LOGOUT_MUTATION,
  SET_USER_SOCIAL_AUTH_MUTATION,
  SET_USER_SOCIAL_NETWORKS_MUTATION,
  REMOVE_USER_SPORT_MUTATION,
  REMOVE_USER_WORKPLACE_MUTATION,
  REMOVE_USER_TRAINING_MUTATION
} from './mutations'

export const SET_USER_DATA_ACTION = 'SET_USER'
export const SET_USER_ACHIEVEMENTS = 'SET_USER_ACHIEVEMENTS'
export const SET_USER_CONTACTS = 'SET_USER_CONTACTS'
export const SET_USER_AVATAR = 'SET_USER_AVATAR'
export const SET_USER_WORKPLACES = 'SET_USER_WORKPLACES'
export const SET_USER_SOC_NETWORKS = 'SET_USER_SOC_NETWORKS'
export const USER_LOGOUT_ACTION = 'USER_LOGOUT'
export const SET_USER_SOC_AUTH_ACTION = 'SET_USER_SOC_AUTH'
export const LOAD_USER_DATA_ACTION = 'LOAD_USER_DATA'
export const REMOVE_USER_SPORT_ACTION = 'REMOVE_USER_SPORT'
export const REMOVE_USER_WORKPLACE_ACTION = 'REMOVE_USER_WORKPLACE'
export const REMOVE_USER_TRAINING_ACTION = 'REMOVE_USER_TRAINING'

export default {
  [USER_LOGOUT_ACTION]({ commit }) {
    commit(USER_LOGOUT_MUTATION)
  },
  [SET_USER_DATA_ACTION]({ commit }, payload) {
    commit(SET_USER_DATA_MUTATION, payload)
    return Promise.resolve()
  },

  [SET_USER_ACHIEVEMENTS]({ commit }, payload) {
    commit(SET_USER_ACHIEVEMENTS_MUTATION, payload)
    return Promise.resolve()
  },

  [SET_USER_CONTACTS]({ commit }, payload) {
    commit(SET_USER_CONTACTS_MUTATION, payload)
    return Promise.resolve()
  },

  [SET_USER_AVATAR]({ commit }, payload) {
    commit(SET_USER_AVATAR_MUTATION, payload)
    return Promise.resolve()
  },

  [SET_USER_WORKPLACES]({ commit }, payload) {
    commit(SET_USER_WORKPLACES_MUTATION, payload)
    return Promise.resolve()
  },

  [SET_USER_SOC_AUTH_ACTION]({ commit }, payload) {
    commit(SET_USER_SOCIAL_AUTH_MUTATION, payload)
    return Promise.resolve()
  },

  [SET_USER_SOC_NETWORKS]({ commit }, payload) {
    commit(SET_USER_SOCIAL_NETWORKS_MUTATION, payload)
    return Promise.resolve()
  },

  async [LOAD_USER_DATA_ACTION]({ commit }, payload) {
    const config = {
      headers: { 'Content-Language': payload }
    }

    try {
      const {
        data: { data: user }
      } = await this.$api.UserApi.getProfileInfo(config)

      commit(SET_USER_DATA_MUTATION, user)

      return await Promise.resolve()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  },

  [REMOVE_USER_SPORT_ACTION]({ commit }, payload) {
    commit(REMOVE_USER_SPORT_MUTATION, payload)
    return Promise.resolve()
  },

  [REMOVE_USER_WORKPLACE_ACTION]({ commit }, payload) {
    commit(REMOVE_USER_WORKPLACE_MUTATION, payload)
    return Promise.resolve()
  },

  [REMOVE_USER_TRAINING_ACTION]({ commit }, payload) {
    commit(REMOVE_USER_TRAINING_MUTATION, payload)
    return Promise.resolve()
  }
}
