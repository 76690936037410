<template>
  <div class="input-container">
    <ValidationProvider
      ref="provider"
      v-slot="{ errors }"
      :rules="rules"
      :name="name"
    >
      <div>
        <div class="flex-pos">
          <div v-if="desc" slot="label" class="input-label">
            {{ desc }}
          </div>
          <span v-if="isValidate && !firstAttempt" class="error-message">{{
            errors[0]
          }}</span>
          <span v-if="isValidate && serverErr" class="error-message">
            {{ serverErr }}
          </span>
        </div>
        <el-input
          v-if="mask"
          v-model="inputValue"
          v-mask="mask"
          :placeholder="placeholder"
          :rows="3"
          :class="errors[0] || serverErr ? 'invalid' : ''"
          clearable
          :type="type"
          :disabled="disabled"
          :masked="false"
          :autocomplete="autocomplete"
          :name="name"
          :readonly="readonly"
          @focus="$emit('focused')"
        >
          <span
            v-if="prefixContent"
            slot="prefix"
            :class="{ active: isActive }"
            >{{ prefixContent }}</span
          >
        </el-input>
        <el-input
          v-else
          v-model="inputValue"
          :placeholder="placeholder"
          :rows="3"
          :class="errors[0] || serverErr ? 'invalid' : ''"
          clearable
          :type="type"
          :disabled="disabled"
          :autocomplete="autocomplete"
          :name="name"
          :readonly="readonly"
          resize="none"
        />
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'Input',
  components: {
    ValidationProvider
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    rules: {
      type: [Object, String],
      default: ''
    },
    desc: {
      default: '',
      type: String
    },
    placeholder: {
      default: '',
      type: String
    },
    isValidate: {
      default: false,
      type: Boolean
    },
    refLink: {
      type: String,
      default: ''
    },
    confirm: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    mask: {
      default: '',
      type: String
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    firstAttempt: {
      default: false,
      type: Boolean
    },
    readonly: {
      default: false,
      type: Boolean
    },
    prefixContent: {
      type: String,
      default: null
    },
    isPrefixActive: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      inputValue: '',
      serverErr: ''
    }
  },
  computed: {
    ...mapGetters({
      serverErrors: 'common/getError',
      socialNetworks: 'dict/getSocialNetworks',
      socAuth: 'auth/socAuth'
    }),
    isActive() {
      return this.isPrefixActive
    },
    networkId() {
      return this.socAuth?.network_id
    }
  },
  watch: {
    inputValue(newVal) {
      this.$emit('input', newVal)
      this.serverErr = ''
    },
    value(newVal) {
      this.inputValue = newVal
    },
    serverErrors() {
      const errors = this.serverErrors
      if (!errors) return

      if (errors.auth) {
        this.serverErr = this.$t('validation.auth')
      }
      if (errors.passRestore && this.name === 'email') {
        this.serverErr = this.$t('validation.no_user_with_this_email')
      }
      if (errors.passFail && this.type === 'password') {
        this.serverErr = this.$t('validation.password')
      }
      if (errors.social_auth && this.name === 'email') {
        this.serverErr =
          this.$t('validation.social_auth') +
          errors.social_auth +
          this.$t('validation.not_found')
      }
      if (errors.social && this.name === 'email') {
        const { title } = this.socialNetworks.find(
          (el) => el.id === this.networkId
        )
        this.serverErr =
          this.$t('validation.social_auth') +
          title +
          this.$t('validation.already_registered')

        this.setSocAuth(null)
      }
      if (errors.password_confirmation) {
        this.serverErr = this.$t(`validation.password`)
      }
      if (errors[this.name]) {
        this.serverErr = this.$t(`validation.${this.name}`)
      }
      if (errors.recovery) {
        this.serverErr = this.$t('validation.no_user_with_this_password')
      }
    }
  },
  mounted() {
    if (this.value) {
      this.inputValue = this.value
    }
    this.$bus.$on('input.clearServerErr', () => this.clearServerError())
  },
  beforeDestroy() {
    this.$bus.$off('input.clearServerErr')
  },
  methods: {
    ...mapActions({
      setSocAuth: 'auth/SET_USER_SOC_AUTH'
    }),
    clearServerError() {
      this.serverErr = ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~assets/css/variables';

.error-message {
  display: flex;
  align-items: center;
  min-height: 14px;
  font-size: 12px;
  line-height: 13px;
  color: $orange-error;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.flex-pos {
  display: flex;
}
.write-to-trainer {
  .input-label {
    margin-bottom: 5px;
    color: $gray !important;
  }
}

.change-password-block {
  .settings-line {
    .input-container {
      position: relative;

      .flex-pos {
        position: absolute;
        bottom: -13px;
        width: 100%;
      }
    }
  }
}
</style>
