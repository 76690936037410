export const MUTATION_SET_ALL_SPORTS = 'MUTATION_SET_ALL_SPORTS'
export const MUTATION_SET_ALL_FIRST_LETTERS = 'MUTATION_SET_ALL_FIRST_LETTERS'
export const MUTATION_SET_SOCIAL_NETWORKS = 'MUTATION_SET_SOCIAL_NETWORKS'
export const MUTATION_RESET_ALL_SPORTS = 'MUTATION_RESET_ALL_SPORTS'

export default {
  [MUTATION_SET_ALL_SPORTS](state, payload) {
    // Sort by first letters of sport name
    if (payload) {
      state.allSports = payload.sort((a, b) => {
        if (a.value < b.value) return -1
        if (a.value > b.value) return 1
        return 0
      })
    }
  },
  [MUTATION_SET_ALL_FIRST_LETTERS](state, sports) {
    const res = Array.from(new Set(sports.map((el) => el.value[0]).sort()))
    state.allFirstLetters.firstLast = res[0] + ' - ' + res[res.length - 1]
    state.allFirstLetters.all = res
  },
  [MUTATION_SET_SOCIAL_NETWORKS](state, payload) {
    state.socialNetworks = payload
  },
  [MUTATION_RESET_ALL_SPORTS](state) {
    state.allSports = []
  }
}
