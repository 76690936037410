<!-- Компонент нотификаций проекта -->
<template>
  <div />
</template>

<script>
export default {
  name: 'Notifications',
  data: () => ({
    duration: 2000
  }),
  mounted() {
    this.$bus.$on('success', (e) => this.successMessage(e))
    this.$bus.$on('warning', (e) => this.warningMessage(e))
    this.$bus.$on('error', (e) => this.errorMessage(e))
  },
  methods: {
    messageCreate(_text, _iconClass) {
      this.$message({
        dangerouslyUseHTMLString: true,
        showClose: true,
        center: true,
        offset: 30,
        duration: this.duration,
        iconClass: _iconClass,
        message: _text
      })
    },
    successMessage(e) {
      switch (e) {
        case 'save':
          this.messageCreate(
            this.$t('notification.success_save'),
            'el-icon-success'
          )
          break
        case 'comment-success':
          this.messageCreate(
            this.$t('notification.comment_added'),
            'el-icon-success'
          )
          break
        case 'recovery-email-sent':
          this.$alert(
            `<i class="el-icon-success"></i><span>${this.$t(
              'notification.recovery_mail_sent'
            )}</span>`,
            {
              lockScroll: true,
              closeOnClickModal: true,
              dangerouslyUseHTMLString: true,
              customClass: `profile-save-confirm message-sent`,
              callback: () => {}
            }
          )
          break
        case 'message-sent':
          this.$alert(
            `<i class="el-icon-success"></i><span>${this.$t(
              'notification.message_sent'
            )}</span></i><span>${this.$t('notification.we_notify_you')}</span>`,
            {
              lockScroll: true,
              closeOnClickModal: true,
              dangerouslyUseHTMLString: true,
              customClass: `profile-save-confirm message-sent`,
              callback: () => {}
            }
          )
          break
        case 'social-acc-binded':
          this.messageCreate(
            this.$t('notification.account_binded'),
            'el-icon-success'
          )
          break
        case 'social-acc-unbinded':
          this.messageCreate(
            this.$t('notification.account_unbinder'),
            'el-icon-success'
          )
          break
        case 'bookmark-saved':
          this.messageCreate(
            this.$t('notification.add_to_bookmarks'),
            'el-icon-success'
          )
          break
        case 'bookmark-deleted':
          this.messageCreate(
            this.$t('notification.delete_from_bookmarks'),
            'el-icon-success'
          )
          break
      }
    },
    warningMessage(e) {
      switch (e) {
        case 'save-before':
          this.messageCreate(
            this.$t('notification.save_before'),
            'el-icon-warning'
          )
          break
        case 'enter-via-socials':
          this.messageCreate(
            this.$t('notification.enter_via_socials'),
            'el-icon-warning'
          )
          break
        case 'fill-feedback':
          this.messageCreate(
            this.$t('notification.write_review_text'),
            'el-icon-warning'
          )
          break
        case 'set-rating':
          this.messageCreate(
            this.$t('notification.set_a_rating'),
            'el-icon-warning'
          )
          break
        case 'your-feedback-exist':
          this.messageCreate(
            this.$t('notification.your_feedback_already_exist'),
            'el-icon-warning'
          )
          break
        case 'club-not-moderated':
          this.$alert(
            `<i class="el-icon-warning"></i><span>${this.$t(
              'notification.no_club_in_base'
            )}</span>`,
            {
              lockScroll: true,
              closeOnClickModal: true,
              dangerouslyUseHTMLString: true,
              customClass: `profile-save-confirm`,
              callback: () => {}
            }
          )
      }
    },
    errorMessage(e) {
      switch (e) {
        case 'unknown-error':
          this.messageCreate(
            this.$t('notification.unknown_error'),
            'el-icon-warning error'
          )
          break
        case 'soc-network-already-binded':
          this.messageCreate(
            this.$t('notification.soc_network_already_binded'),
            'el-icon-warning error'
          )
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
