<template>
  <div class="burger-container">
    <div id="nav-icon" ref="burger" @click="toggleFunc">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>

    <el-dialog
      :visible.sync="show"
      width="100%"
      :modal="false"
      :append-to-body="true"
      class="navigation"
      :before-close="toggleFunc"
    >
      <div class="header-block">
        {{ $t('common.menu') }}
      </div>
      <ul>
        <li>
          <nuxt-link
            :to="
              localePath({
                name: 'location-profile-index',
                params: { location: city }
              })
            "
            @click.native="toggleFunc"
          >
            <span>{{ $t('common.about_me') }}</span>
          </nuxt-link>
        </li>
        <li>
          <nuxt-link
            :to="
              localePath({
                name: 'location-profile-index-workplaces',
                params: { location: city }
              })
            "
            @click.native="toggleFunc"
          >
            <span>{{ $t('common.workplace') }}</span>
          </nuxt-link>
        </li>
        <li>
          <nuxt-link
            :to="
              localePath({
                name: 'location-profile-index-achievements',
                params: { location: city }
              })
            "
            @click.native="toggleFunc"
          >
            <span>{{ $t('common.achievements') }}</span>
          </nuxt-link>
        </li>
        <li>
          <nuxt-link
            :to="
              localePath({
                name: 'location-profile-index-contacts',
                params: { location: city }
              })
            "
            @click.native="toggleFunc"
          >
            <span>{{ $t('common.contacts') }}</span>
          </nuxt-link>
        </li>
      </ul>
      <ul>
        <li>
          <nuxt-link
            :to="
              localePath({
                name: 'location-profile-index-password-change',
                params: { location: city }
              })
            "
            @click.native="toggleFunc"
          >
            <span>{{ $t('common.change_password') }}</span>
          </nuxt-link>
        </li>
        <li>
          <nuxt-link
            :to="
              localePath({
                name: 'location-trainer-id',
                params: { id: userURL, location: city }
              })
            "
            @click.native="toggleFunc"
          >
            <span>{{ $t('common.profile_view') }}</span>
          </nuxt-link>
        </li>
      </ul>
      <ul>
        <li>
          <button class="exit-button" @click="logoutUser">
            {{ $t('common.exit') }}
          </button>
        </li>
      </ul>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ScrollDisablerMixin from '@/dependencies/mixins/scroll-disabler'

export default {
  name: 'BurgerMenu',
  mixins: [ScrollDisablerMixin],
  props: {
    logout: {
      type: Function,
      default: () => 1
    }
  },
  data: () => ({
    show: false
  }),
  computed: {
    ...mapGetters({
      city: 'location/getUrlKey'
    }),
    user() {
      return this.$auth.user
    },
    userURL() {
      return this.user?.url
    }
  },
  beforeDestroy() {
    this.styleToggler('unset')
  },
  methods: {
    toggleFunc() {
      this.$refs.burger.classList.toggle('open')
      this.show = !this.show
    },
    logoutUser() {
      this.logout()
      this.toggleFunc()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~assets/css/variables';

@media (min-width: 768px) {
  .burger-container {
    display: none;
  }
}

#nav-icon {
  z-index: 210;
  width: 25px;
  height: 16px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  outline: none;
}

#nav-icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: $primary;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;

  &:nth-child(1) {
    top: 0;
  }
  &:nth-child(2),
  &:nth-child(3) {
    top: 7px;
  }
  &:nth-child(4) {
    top: 14px;
  }
}

#nav-icon.open {
  span {
    background: #ffffff;

    &:nth-child(1) {
      top: 18px;
      width: 0;
      left: 50%;
    }

    &:nth-child(2) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    &:nth-child(4) {
      top: 18px;
      width: 0;
      left: 50%;
    }
  }
}

.navigation {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  overflow: hidden;
  opacity: 1;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  text-align: left;
  line-height: 1.5;
  transition: 0.25s ease-in-out;

  .header-block {
    height: 50px;
    line-height: 50px;
    background-color: $primary;
    color: #fffefe;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
  }

  ul {
    padding: 25px 0;
    position: relative;

    &:after {
      position: absolute;
      bottom: 0;
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: #e5e5e5;
      margin: 0 auto;
    }

    &:nth-last-of-type(3n-2) {
      &:after {
        display: none;
      }
    }

    li {
      list-style: none;
      font-size: 18px;
      line-height: 50px;

      a {
        display: block;
        color: #808080;
        text-decoration: none;

        &.nuxt-link-exact-active {
          background-color: $gray-active;
          font-weight: bold;
          color: $primary;
        }

        span {
          padding: 0 25px;
        }
      }

      .exit-button {
        width: 100%;
        color: #808080;

        &:hover {
          font-weight: bold;
        }
      }
    }
  }
}
</style>
