export default {
  API: {
    PREFIX: '/api/v1',
    ROUTES: {
      ACCOUNT: {
        SIGN_UP: '/account/sign-up',
        RESET_PASSWORD: '/account/reset-password',
        SEND_RESET_PASSWORD_LINK: '/account/send-reset-link',
        CHANGE_PASSWORD: '/trainer/change-password',
        GET_TRAINER_INFO: '/trainer',
        SIGN_IN_WITH_FACEBOOK: '/account/sign-in-facebook',
        SIGN_IN_WITH_GOOGLE: '/account/sign-in-google'
      },
      USER: {
        UPDATE_INFORMATION: '/trainer/about-me',
        TRAINERS_LIST: '/trainer/search-trainer-list',
        TRAINERS_CLUBS: '/trainer/search-club-list',
        UPDATE_WORKPLACES: '/trainer/workplace',
        UPDATE_ACHIEVEMENTS: '/trainer/achievement',
        UPDATE_CONTACTS: '/trainer/contact',
        UPDATE_TRAINER_AVATAR: '/trainer/avatar',
        ADD_SOCIAL_NETWORK_LINK: '/trainer/social-link/add',
        REMOVE_SOCIAL_NETWORK_LINK: (social_id) =>
          `/trainer/social-link/${social_id}`,
        REMOVE_SPORT: (id) => `/trainer/trainer-sport/${id}`,
        REMOVE_CLUB: (id) => `/trainer/trainer-club/${id}`,
        REMOVE_TRAINING: (id) => `/trainer/trainer-training/${id}`
      },
      SPORT: {
        ALL_SPORTS: '/sport/all',
        CLUBS_SPORTS: '/club-sport/suggest'
      },
      LOCATION: {
        CITY_DISTRICTS: '/city/districts',
        POPULAR_CITIES: '/city/popular-cities',
        SUBWAY_LINES: '/subway/line/all',
        SUBWAY_STATIONS: '/subway/station/all'
      },
      DICT: {
        ALL_SPORTS: '/sport/all',
        GET_CITIES: '/city/suggest',
        GET_CLUBS: '/club/suggest',
        GET_INSTITUTIONS: '/institution/suggest'
      },
      SPORTS: {
        TRAINING_TYPES: (id) => `/training-type/${id}/all`
      },
      PUBLIC: {
        TRAINER_INFO: (id) => `/trainer/${id}/public`,
        TRAINER_FEEDBACK_MSGS: (id) => `/trainer/${id}/feedback/all`,
        SPORT_CLUB_INFO: (id) => `/club/${id}`,
        SEND_MESSAGE_TO_TRAINER: '/trainer/feedback',
        GET_OPEN_CONTACTS: (id, recaptcha) =>
          `/trainer/${id}/phone-and-email/${recaptcha}`
      },
      COMMENT: {
        GET_SOCIAL_NETWORKS: '/social-network/all',
        POST_COMMENT: (network) => `/comment/social/${network}`
      }
    }
  },
  COMMON: {
    MIN_AGE: 16,
    MAX_AGE: 80,
    MIN_COST: 0,
    MAX_COST: 1000,
    MOBILE_SIZE: 991,
    INITIAL_ZOOM_LEVEL: 3,
    CITY_ZOOM_LEVEL: 12,
    SPORT_PROPERTY: 'sport',
    CLUB_PROPERTY: 'club'
  },
  COLORS: {
    PRIMARY_COLOR: '#2f8e97'
  },
  PHONE: {
    UA: {
      FIRST: '+38 (050) 368-38-15',
      SECOND: '+38 (067) 248-38-15'
    }
  },
  DOMAIN: {
    PL: 'pl',
    EE: 'ee',
    LV: 'lv',
    LT: 'lt',
    BY: 'by',
    UA: 'ua'
  },
  // For SEO meta
  DOMAIN_NAME: {
    pl: 'Treners.PL',
    ee: 'Trainer.EE',
    lv: 'Treneri.LV',
    lt: 'Treneriai.LT',
    by: 'Beltrener.BY',
    ua: 'Trener.UA'
  },
  FOOTER_INFO: {
    pl: {
      SOCIAL_LINKS: {
        insta: 'https://www.instagram.com/treneriai.lt/',
        fb:
          'https://www.fb.com/pg/TrainerPL-Znajdź-swojego-trenera-177056829371568/'
      },
      PHONE: '+48459567479',
      SUPPORT: '',
      EMAIL: 'info@treners.pl',
      ADDRESS: 'pl',
      COUNTRY_NAME: 'country_in.pl'
    },
    ee: {
      SOCIAL_LINKS: {
        insta: 'https://www.instagram.com/treneri.lv/',
        fb:
          'https://www.facebook.com/TreeneridEE-Leia-oma-treener-1076284522459959/'
      },
      PHONE: '+370 (655) 15515',
      SUPPORT: '',
      EMAIL: 'info@treneriai.lt',
      ADDRESS: 'lv',
      COUNTRY_NAME: 'country_in.ee'
    },
    lv: {
      SOCIAL_LINKS: {
        insta: 'https://www.instagram.com/treneri.lv/',
        fb: 'https://www.facebook.com/trenerilv/'
      },
      PHONE: '+370 (655) 15515',
      SUPPORT: '',
      EMAIL: 'info@treneriai.lt',
      ADDRESS: 'lv',
      COUNTRY_NAME: 'country_in.lv'
    },
    lt: {
      SOCIAL_LINKS: {
        insta: 'https://www.instagram.com/treneriai.lt/',
        fb: 'https://www.facebook.com/treneriailt/'
      },
      PHONE: '+370 (655) 15515',
      SUPPORT: '',
      EMAIL: 'info@treneriai.lt',
      ADDRESS: 'lt',
      COUNTRY_NAME: 'country_in.lt'
    },
    by: {
      SOCIAL_LINKS: {
        insta: 'https://www.instagram.com/trener.ua/',
        fb: 'https://www.facebook.com/trenerua'
      },
      PHONE: '+38 (067) 328-71-41',
      SUPPORT: '',
      EMAIL: 'info@trener.ua',
      ADDRESS: 'ua',
      COUNTRY_NAME: 'country_in.by'
    },
    ua: {
      SOCIAL_LINKS: {
        insta: 'https://www.instagram.com/trener.ua/',
        fb: 'https://www.facebook.com/trenerua'
      },
      PHONE: '+38 (067) 328-71-41',
      SUPPORT: {
        first: '+38 (050) 368-38-15',
        second: '+38 (067) 248-38-15'
      },
      EMAIL: 'info@trener.ua',
      ADDRESS: 'ua',
      COUNTRY_NAME: 'country_in.ua'
    }
  },
  LANGUAGES: [
    { name: 'українська', value: 'uk' },
    { name: 'русский', value: 'ru' },
    { name: 'english', value: 'en' },
    { name: 'lietuvių', value: 'lt' },
    { name: 'polski', value: 'pl' },
    { name: 'español', value: 'es' }
  ],
  CAPITALS: {
    ua: {
      id: 703448,
      name: 'Киев',
      url_key: 'kiev',
      langPrefix: 'ru'
    },
    lt: {
      id: 593116,
      name: 'Vilnius',
      url_key: 'vilnius',
      langPrefix: 'lt'
    },
    lv: {
      id: 8522019,
      name: 'Riga',
      url_key: 'riga',
      langPrefix: 'en'
    },
    ee: {
      id: 588409,
      name: 'Tallin',
      url_key: 'tallin',
      langPrefix: 'en'
    },
    pl: {
      id: 756135,
      name: 'Warsaw',
      url_key: 'warsaw',
      langPrefix: 'pl'
    },
    by: {
      id: 8522014,
      name: 'Минск',
      url_key: 'minsk',
      langPrefix: 'ru'
    }
  },
  TIMES: [
    '6:00',
    '7:00',
    '8:00',
    '9:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00'
  ],
  citiesWithSubwayDistricts: [
    'kiev',
    'dnipro',
    'kharkiv',
    'lvov',
    'odesa',
    'zaporizhia',
    'warsaw',
    'wroclaw',
    'poznan',
    'krakov'
  ],
  statusCode: {
    client: 401,
    server: 500
  },
  headerHeight: {
    mobile: 40,
    desktop: 80
  },
  CACHED_PATHS: [
    'location-search-trainers-all',
    'location-trainer-id',
    'location-club-id'
  ],
  EXISTING_PATHS: ['/profile', '/search/trainers/', '/privacy']
}
