import { arrSortMaxMin } from '@/dependencies/helpers/arr-sorter'

export const UPDATE_COMMENTS_MUTATION = 'UPDATE_COMMENTS_MUTATION'
export const UPDATE_RATING_MUTATION = 'UPDATE_RATING_MUTATION'
export const SET_RATING_MUTATION = 'SET_RATING_MUTATION'

export default {
  [UPDATE_COMMENTS_MUTATION](state, payload) {
    state.comments = arrSortMaxMin(payload, 'id')
  },

  [UPDATE_RATING_MUTATION](state, payload) {
    state.rating[payload] += 1
  },

  [SET_RATING_MUTATION](state, payload) {
    state.rating = payload
  }
}
