export const SET_POPULAR_CITIES_MUTATION = 'SET_POPULAR_CITIES_MUTATION'
export const SET_URL_KEY_MUTATION = 'SET_URL_KEY_MUTATION'
export const SET_CITY_NAME_MUTATION = 'SET_CITY_NAME_MUTATION'
export const SET_DOMAIN_LANG_MUTATION = 'SET_DOMAIN_LANG_MUTATION'

export default {
  [SET_POPULAR_CITIES_MUTATION](state, payload) {
    state.popularCities = payload
  },

  [SET_URL_KEY_MUTATION](state, payload) {
    state.url_key = payload
  },

  [SET_CITY_NAME_MUTATION](state, payload) {
    state.city_name = payload
  },

  [SET_DOMAIN_LANG_MUTATION](state, payload) {
    state.domain = payload
  }
}
