<template>
  <div class="custom-container">
    Custom error layout here
  </div>
</template>

<script>
export default {
  name: 'Error'
}
</script>

<style scoped></style>
