import constants from '@/dependencies/constants'

export default function({ route, redirect, app }) {
  const lang = app.$cookies.get('locale')
  const url_key = app.$cookies.get('url_key')

  const { name, path, query, fullPath } = route

  const { EXISTING_PATHS } = constants

  const pathExist = EXISTING_PATHS.includes(path)

  if (!name && path !== '/' && !pathExist) {
    redirect('301', `/`)
  } else if (!name && path !== '/') {
    redirect('301', `/${lang}/${url_key}${path}`)
  } else if (path === '/' && Object.keys(query).length) {
    redirect('301', `/${lang}/${url_key}${fullPath}`)
  }
}
