import {
  UPDATE_COMMENTS_MUTATION,
  UPDATE_RATING_MUTATION,
  SET_RATING_MUTATION
} from './mutations'

export const UPDATE_COMMENTS_ACTION = 'UPDATE_COMMENTS'
export const UPDATE_RATING_ACTION = 'UPDATE_RATING'
export const SET_RATING_ACTION = 'SET_RATING'

export default {
  [UPDATE_COMMENTS_ACTION]({ commit }, payload) {
    commit(UPDATE_COMMENTS_MUTATION, payload)
    return Promise.resolve()
  },

  [UPDATE_RATING_ACTION]({ commit }, payload) {
    commit(UPDATE_RATING_MUTATION, payload)
    return Promise.resolve()
  },

  [SET_RATING_ACTION]({ commit }, payload) {
    commit(SET_RATING_MUTATION, payload)
    return Promise.resolve()
  }
}
