export default class PhoneMasker {
  static GetMask(url) {
    const urlSplit = url.split('.')

    const country = urlSplit[urlSplit.length - 1]

    switch (country) {
      case 'lv': {
        return { prefix: 371, mask: '########', placeholder: '___-_____' }
      }
      case 'lt': {
        return { prefix: 370, mask: '########', placeholder: '___-_____' }
      }
      case 'ee': {
        return { prefix: 372, mask: '#########', placeholder: '___-______' }
      }
      case 'pl': {
        return { prefix: 48, mask: '#########', placeholder: '__-_______' }
      }
      case 'by': {
        return { prefix: 375, mask: '#########', placeholder: '__-___-____' }
      }
      case 'ua': {
        return {
          prefix: 38,
          mask: '###-###-##-##',
          placeholder: '___-___-__-__'
        }
      }
      default: {
        return {
          prefix: 380,
          mask: '###-###-##-##',
          placeholder: '___-___-__-__'
        }
      }
    }
  }
}
