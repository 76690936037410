import constants from '@/dependencies/constants'
import BaseApi from '@/api/base'

export default class LocationApi extends BaseApi {
  getSubwayLines(params) {
    return this.$axios.get(constants.API.ROUTES.LOCATION.SUBWAY_LINES, params)
  }

  getSubwayStations(params) {
    return this.$axios.get(
      constants.API.ROUTES.LOCATION.SUBWAY_STATIONS,
      params
    )
  }

  getCityDistricts(params) {
    return this.$axios.get(constants.API.ROUTES.LOCATION.CITY_DISTRICTS, params)
  }

  getPopularCities(params, _lang) {
    this.$axios.setHeader('Content-Language', _lang)
    return this.$axios.get(constants.API.ROUTES.LOCATION.POPULAR_CITIES, params)
  }
}
