const middleware = {}

middleware['main-page-redirect'] = require('../middleware/main-page-redirect.js')
middleware['main-page-redirect'] = middleware['main-page-redirect'].default || middleware['main-page-redirect']

middleware['moment-localize'] = require('../middleware/moment-localize.js')
middleware['moment-localize'] = middleware['moment-localize'].default || middleware['moment-localize']

middleware['search-result-caching'] = require('../middleware/search-result-caching.js')
middleware['search-result-caching'] = middleware['search-result-caching'].default || middleware['search-result-caching']

middleware['server-redirect'] = require('../middleware/server-redirect.js')
middleware['server-redirect'] = middleware['server-redirect'].default || middleware['server-redirect']

middleware['validate-localize'] = require('../middleware/validate-localize.js')
middleware['validate-localize'] = middleware['validate-localize'].default || middleware['validate-localize']

export default middleware
