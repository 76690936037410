import {
  SET_POPULAR_CITIES_MUTATION,
  SET_URL_KEY_MUTATION,
  SET_CITY_NAME_MUTATION,
  SET_DOMAIN_LANG_MUTATION
} from './mutations'
import DomainSelector from '@/dependencies/helpers/domain-selector'

export const LOAD_POPULAR_CITIES_ACTION = 'LOAD_POPULAR_CITIES'
export const LOAD_URL_KEY_ACTION = 'LOAD_URL_KEY'
export const LOAD_CITY_ACTION = 'LOAD_CITY'
export const SET_CURRENT_CITY_NAME_ACTION = 'SET_CURRENT_CITY_NAME'
export const SET_DOMAIN_LANG_ACTION = 'SET_DOMAIN_LANG'

export default {
  [SET_DOMAIN_LANG_ACTION]({ commit }) {
    const { name } = new DomainSelector.GetDomain(window.location.hostname)
    commit(SET_DOMAIN_LANG_MUTATION, name)
    return Promise.resolve()
  },

  [LOAD_URL_KEY_ACTION]({ commit }, payload) {
    commit(SET_URL_KEY_MUTATION, payload)
    return Promise.resolve()
  },

  [SET_CURRENT_CITY_NAME_ACTION]({ commit }, payload) {
    commit(SET_CITY_NAME_MUTATION, payload)
    return Promise.resolve()
  },

  async [LOAD_CITY_ACTION]({ commit, dispatch, state }, payload) {
    const { title, newLang } = payload
    const lang = newLang || this.$i18n.locale
    const { domain } = state

    try {
      const {
        data: { data }
      } = await this.$api.DictApi.getCities(
        {
          params: {
            title,
            'country-iso': domain
          }
        },
        lang
      )

      if (!data.length) {
        this.$router.push('_/404')
      }

      const { id, url_key, label } = data[0]

      commit(SET_CITY_NAME_MUTATION, label)

      await dispatch('LOAD_URL_KEY', url_key)
      await dispatch('search/SET_LOCATION', id, { root: true })

      return Promise.resolve()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  },

  async [LOAD_POPULAR_CITIES_ACTION]({ state, commit, dispatch }, payload) {
    if (state?.popularCities?.length && !payload) return

    const lang = payload || this.$i18n.locale

    const { name } = new DomainSelector.GetDomain(window.location.hostname)

    try {
      const {
        data: { data }
      } = await this.$api.LocationApi.getPopularCities(
        {
          params: {
            domain: name
          }
        },
        lang
      )
      commit(SET_POPULAR_CITIES_MUTATION, data)

      await dispatch('search/LOAD_SUBWAY_MAP', null, { root: true })
      await dispatch('search/LOAD_CITY_DISTRICTS', null, { root: true })

      return Promise.resolve()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }
}
