<template>
  <div v-if="!internalError" id="app">
    <Header />
    <nuxt />
    <Footer />
    <Login />
    <PasswordRecovery />
    <Notifications />
    <el-backtop />
  </div>
  <InternalServerError v-else />
</template>

<script>
import { mapGetters } from 'vuex'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Login from '@/components/Auth/Login'
import Notifications from '@/components/Notifications'
import InternalServerError from '@/components/InternalServerError'
import PasswordRecovery from '@/components/Auth/PasswordRecovery'

export default {
  components: {
    Header,
    Footer,
    Login,
    PasswordRecovery,
    Notifications,
    InternalServerError
  },
  computed: {
    ...mapGetters({
      internalError: 'common/getInternalError'
    })
  },
  created() {
    this.$auth.fetchUser()
  }
}
</script>

<style></style>
