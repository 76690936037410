import { GOOGLE_CLIENT_ID } from '@/config/socials'

if (process.client) {
  window.gapiOnLoadCallback = () => {
    window.gapi.load('auth2', () => {
      window.google_auth2 = window.gapi.auth2.init({
        client_id: GOOGLE_CLIENT_ID
      })
    })
  }
  const installGoogleSdkScript = (d, s, id) => {
    if (d.getElementById(id)) {
      this.google_sdk_initialized = true
      return
    }
    const fjs = d.getElementsByTagName(s)[0]
    const js = d.createElement(s)
    js.id = id
    js.defer = true
    js.src = 'https://apis.google.com/js/platform.js?onload=gapiOnLoadCallback'
    fjs.parentNode.insertBefore(js, fjs)
  }
  installGoogleSdkScript(document, 'script', 'google-jssdk')
}
