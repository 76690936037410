export const SET_DOMAIN_INFO_MUTATION = 'SET_DOMAIN_INFO_MUTATION'

export default {
  [SET_DOMAIN_INFO_MUTATION](state, payload) {
    const { phoneMask, currObj, footer } = payload

    state.phoneMask = phoneMask
    state.currency = currObj
    state.footerInfo = footer
  }
}
