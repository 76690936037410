import constants from '@/dependencies/constants'

export default {
  searchSportType: (state) => state.sportType,
  searchSportClub: (state) => state.sportClub,
  searchLocation: (state) => state.location,
  searchDistrict: (state) => state.searchDistr,
  searchMicroDistrict: (state) => state.searchMicroDistrict,
  searchSubway: (state) => state.searchSubway,
  searchGender: (state) => state.searchGender,
  searchAge: (state) => state.searchAge,
  searchCost: (state) => state.searchCost,
  searchSchedule: (state) => state.searchSchedule,
  searchScheduleStr: (state) => state.searchScheduleStr,
  searchOutdoor: (state) => state.outdoor,
  searchSort: (state) => state.sort,
  searchPage: (state) => state.searchPage,
  getBookmarksNumber: (state) => state.bookmarksNumber,
  getBookmarksList: (state) => state.bookmarks,
  getTrainersList: (state) => state.trainersList,
  getSeo: (state) => state.seo,
  getPagesNumber: (state) => (state.meta ? state.meta.last_page : undefined),
  getTotal: (state) => (state.meta ? state.meta.total : undefined),
  getFrom: (state) => (state.meta ? state.meta.from : undefined),
  getTo: (state) => (state.meta ? state.meta.to : undefined),
  isBookmarksShown: (state) => state.isBookmarksShown,
  getSubwayLines: (state) => state.subwayLines,
  getSubwayStations: (state) => state.subwayStations,
  getClubsList: (state) => state.clubsList,
  getSubwayMap: (state) => state.subwayMap,
  getSubwayLinesMap: (state) => state.subwayLinesMap,
  getCommonSubwayList: (state) => state.commonSubwayStationsList,
  getCityDistricts: (state) => state.cityDistricts,
  getQueryParameters: (state) => {
    const {
      location,
      sportType,
      sportClub,
      searchGender,
      outdoor,
      searchPage,
      sort,
      searchCost,
      searchAge,
      searchSubway,
      searchMicroDistrict,
      searchDistr,
      searchSchedule
    } = state

    const query = {
      city: location,
      sport: sportType ? sportType.id : undefined,
      sportUrl: sportType ? sportType.url : undefined,
      club: sportClub ? sportClub.id : undefined,
      clubUrl: sportClub ? sportClub.url : undefined,
      districts: searchDistr.map((item) => item.id),
      microDistricts: searchMicroDistrict.map((item) => item.id),
      gender: searchGender,
      outdoor,
      page: searchPage,
      sort,
      subwayStations: searchSubway
    }

    if (searchSchedule) query.schedule_ids = searchSchedule

    if (searchCost)
      query.price = {
        from: searchCost[0] ?? constants.COMMON.MIN_COST,
        to: searchCost[1] ?? constants.COMMON.MAX_COST
      }
    if (searchAge)
      query.age = {
        from: searchAge[0] ?? constants.COMMON.MIN_AGE,
        to: searchAge[1] ?? constants.COMMON.MAX_AGE
      }

    return query
  },
  getTrainersListFilling: (state) => state.emptyTrainersList,
  getCachedTrainers: (state) => state.cachedTrainers,
  getFocusedTrainer: (state) => state.focusedTrainer,
  getResetStatus: (state) => state.resetFiltersStatus,
  getRequestStatus: (state) => state.requestStatus
}
