import constants from '@/dependencies/constants'

const {
  DOMAIN_NAME: { ua }
} = constants

export default () => ({
  cityLocation: {
    name: null,
    domainTitle: ua
  },
  meta: null,
  title: null
})
