<template>
  <el-dialog
    class="login-modal"
    :visible.sync="show"
    width="100%"
    :center="true"
    :lock-scroll="true"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    @close="serverErrReset"
    @closed="isLoading = false"
  >
    <ValidationObserver ref="observer">
      <div class="form-header">
        <span :class="{ active: tab === 'login' }" @click="tab = 'login'">{{
          $t('common.enter')
        }}</span>
        <span
          :class="{ active: tab === 'register' }"
          @click="tab = 'register'"
          >{{ $t('common.register_trener') }}</span
        >
      </div>
      <form v-if="tab === 'login'" class="form" @keypress.13="login">
        <div class="form-line">
          <i class="icon-email-lined" />
          <Input
            v-model="email"
            rules="required|email"
            name="email"
            type="email"
            :is-validate="true"
            clearable
            :placeholder="$t('placeholder.your_email')"
          />
        </div>
        <div class="form-line">
          <i class="icon-password-lined" />
          <Input
            v-model="password"
            rules="required|min:6|max:191"
            name="password"
            type="password"
            :is-validate="true"
            :placeholder="$t('placeholder.password')"
            clearable
          />
        </div>
        <div v-if="!isLoading" class="buttons-container">
          <div class="buttons-wrapper">
            <el-button type="warning" class="enter-btn" @click="login">
              {{ $t('common.enter') }}
            </el-button>
            <button class="borderless-button" @click.prevent="passwordRecovery">
              {{ $t('common.forgot_pass') }}
            </button>
          </div>
          <p class="enter-with">
            {{ $t('common.or_enter_via') }}
          </p>
          <div class="buttons-wrapper mt-0">
            <button
              v-if="!hideSocialLogin"
              class="fb-button"
              @click.prevent="signInWithNetwork('Facebook')"
            />
            <button
              class="google-button"
              @click.prevent="signInWithNetwork('Google')"
            />
          </div>
        </div>
        <div v-else class="loader-container">
          <i class="el-icon-loading" />
        </div>
      </form>
      <div v-else class="form">
        <Registration :profile-redirect="profileRedirect" />
      </div>
    </ValidationObserver>
  </el-dialog>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'
import Input from '@/components/Common/Input'
import Registration from '@/components/Auth/Registration'
import ServErrorResetMixin from '@/dependencies/mixins/server-errors-reset'
import SocialAuthMixin from '@/dependencies/mixins/social-auth'
import DomainSelector from '@/dependencies/helpers/domain-selector'

export default {
  name: 'Login',
  components: {
    Input,
    Registration,
    ValidationObserver
  },
  mixins: [ServErrorResetMixin, SocialAuthMixin],
  data: () => ({
    email: null,
    password: null,
    tab: 'login',
    show: false,
    isLoading: false,
    hideSocialLogin: false,
  }),
  computed: {
    ...mapGetters({
      city: 'location/getUrlKey',
      socialAuth: 'auth/socAuth'
    })
  },
  mounted() {
    this.hideSocialLogin = new DomainSelector().isPLDomain(window.location.hostname)
    this.$bus.$on('openLogin', (_reg) => this.openModal(_reg))
    this.$bus.$on('closeRegModal', (_int) => this.closeModal(_int))
  },
  methods: {
    ...mapActions({
      setError: 'common/SET_ERROR_MESSAGE',
      setSocAuth: 'auth/SET_USER_SOC_AUTH'
    }),
    openModal(_reg) {
      this.tab = _reg ? 'register' : 'login'
      this.show = true
    },
    closeModal(_interval = 0) {
      setTimeout(() => {
        this.show = false
      }, _interval)
    },
    async login() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) return

      this.isLoading = true

      try {
        await this.$auth.loginWith('local', {
          data: {
            email: this.email,
            password: this.password
          }
        })
        this.closeModal(250)
        this.profileRedirect()
      } catch (err) {
        const {
          response: {
            data: { message }
          }
        } = err

        this.isLoading = false

        await this.setError({ auth: [message] })

        // eslint-disable-next-line no-console
        console.log(err)
      }
    },

    async signInWithNetwork(_network) {
      switch (_network) {
        case 'Facebook':
          await this.loginWithFacebook()
          break
        case 'Google':
          await this.loginWithGoogle()
          break
        default:
          return
      }

      if (!this.socialAuth) return

      this.isLoading = true

      try {
        const { access_token } = this.socialAuth
        const {
          data: { access_token: token }
        } = await this.$api.AuthApi[`signInWith${_network}`]({ access_token })

        await Promise.all([
          this.$auth.setUserToken(token),
          this.$auth.fetchUser()
        ])
        this.closeModal(250)
        await this.setSocAuth(null)
        this.profileRedirect()
      } catch (e) {
        this.isLoading = false
        const {
          response: {
            data: { message }
          }
        } = e

        if (message === 'Unauthorized') {
          await this.setError({ social_auth: _network })
        }
        // eslint-disable-next-line no-console
        console.log(e)
      }
    },

    profileRedirect() {
      this.$router.push(
        this.localePath({
          name: 'location-profile-index',
          params: {
            location: this.city
          }
        })
      )
    },

    passwordRecovery() {
      this.closeModal()
      this.$bus.$emit('recoveryModal')
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~assets/css/variables';

.form-header {
  height: 50px;
  width: 100%;
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
  color: white;
  background-color: $primary;
  display: flex;
  justify-content: space-between;
  padding: 13px 12% 13px 13px;
  span {
    text-decoration: underline;
    order: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      cursor: pointer;
    }
  }
  .active {
    text-transform: uppercase;
    text-decoration: none !important;
    font-weight: bold;
    order: 0;
  }
}
.form {
  padding: 13px 13px 13px 48px;

  &-line {
    position: relative;

    i {
      font-size: 24px;
      color: $primary;
      position: absolute;
      left: -35px;
      top: 22px;
    }
  }

  .enter-with {
    font-size: 13px;
    line-height: 36px;
    text-align: center;
  }
}
.main-button {
  min-height: 40px;
  max-height: 40px;
  text-transform: none;
}
.buttons-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .borderless-button {
    font-size: 16px;
    min-width: 106px;
    width: 55%;
  }
}

.buttons-container,
.loader-container {
  margin-top: 24px;
}

.loader-container {
  display: flex;
  align-items: center;
  min-height: 115px;
  padding: 0 42%;

  i {
    font-size: 35px;
    color: $primary;
  }
}
@media (min-width: 425px) {
  .form-header {
    font-size: 18px;
    line-height: 1.2;

    span {
      line-height: 1.1;
    }
  }
}

@media (min-width: 768px) {
  .form {
    padding: 20px 20px 20px 58px;
  }

  .form-header {
    border-bottom: 2px solid #1c5459;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 13px 90px;

    .active {
      font-size: 22px;
      font-weight: normal;
    }

    span:not(.active) {
      display: none;
    }
  }
  .buttons-wrapper {
    .borderless-button {
      font-size: 16px;
      &:hover {
        box-shadow: none;
      }
    }

    button {
      transition: 0.2s linear;
      &:hover {
        transition: 0.2s linear;
        box-shadow: 0 1px 9px rgba(0, 0, 0, 0.4);
      }
    }
  }
}
</style>
