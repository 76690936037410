import constants from '@/dependencies/constants'
import BaseApi from '@/api/base'

export default class AuthApi extends BaseApi {
  register(userRegisterData) {
    return this.$axios.post(
      constants.API.ROUTES.ACCOUNT.SIGN_UP,
      userRegisterData
    )
  }

  passwordRecovery(email) {
    return this.$axios.post(
      constants.API.ROUTES.ACCOUNT.SEND_RESET_PASSWORD_LINK,
      email
    )
  }

  sendResetPassword(params) {
    return this.$axios.post(constants.API.ROUTES.ACCOUNT.RESET_PASSWORD, params)
  }

  signInWithFacebook(token) {
    return this.$axios.post(
      constants.API.ROUTES.ACCOUNT.SIGN_IN_WITH_FACEBOOK,
      token
    )
  }

  signInWithGoogle(token) {
    return this.$axios.post(
      constants.API.ROUTES.ACCOUNT.SIGN_IN_WITH_GOOGLE,
      token
    )
  }
}
