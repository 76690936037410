import constants from '@/dependencies/constants'
import BaseApi from '@/api/base'

export default class UserApi extends BaseApi {
  updateUserInformation(data) {
    return this.$axios.put(constants.API.ROUTES.USER.UPDATE_INFORMATION, data)
  }

  getTrainersList(data) {
    return this.$axios.post(constants.API.ROUTES.USER.TRAINERS_LIST, data)
  }

  getTrainersClubs(data) {
    return this.$axios.post(constants.API.ROUTES.USER.TRAINERS_CLUBS, data)
  }

  updateUserWorkplaces(data, _method) {
    return this.$axios[_method](
      constants.API.ROUTES.USER.UPDATE_WORKPLACES,
      data
    )
  }

  updateUserAchievements(data) {
    return this.$axios.put(constants.API.ROUTES.USER.UPDATE_ACHIEVEMENTS, data)
  }

  updateUserContacts(data) {
    return this.$axios.put(constants.API.ROUTES.USER.UPDATE_CONTACTS, data)
  }

  updateUserAvatar(data) {
    return this.$axios.post(
      constants.API.ROUTES.USER.UPDATE_TRAINER_AVATAR,
      data
    )
  }

  changeUserPassword(data) {
    return this.$axios.post(constants.API.ROUTES.ACCOUNT.CHANGE_PASSWORD, data)
  }

  getProfileInfo(config) {
    return this.$axios.get(
      constants.API.ROUTES.ACCOUNT.GET_TRAINER_INFO,
      config
    )
  }

  addSocialNetworkLink(params) {
    return this.$axios.post(
      constants.API.ROUTES.USER.ADD_SOCIAL_NETWORK_LINK,
      params
    )
  }

  removeSocialNetworkLink(_social_id) {
    return this.$axios.delete(
      constants.API.ROUTES.USER.REMOVE_SOCIAL_NETWORK_LINK(_social_id)
    )
  }

  removeTrainerSport(_id) {
    return this.$axios.delete(constants.API.ROUTES.USER.REMOVE_SPORT(_id))
  }

  removeTrainerClub(_id) {
    return this.$axios.delete(constants.API.ROUTES.USER.REMOVE_CLUB(_id))
  }

  removeClubTraining(_id) {
    return this.$axios.delete(constants.API.ROUTES.USER.REMOVE_TRAINING(_id))
  }
}
