import constants from '@/dependencies/constants'

export default function({ $axios, store, isDev, req }) {
  $axios.setHeader('Content-Type', 'application/json')
  $axios.setHeader('Accept', 'application/json')
  if (!isDev) {
    if (process.server) {
      $axios.setBaseURL(`https://${req.headers.host}${constants.API.PREFIX}`)
    } else {
      $axios.setBaseURL(
        `https://${window.location.hostname}${constants.API.PREFIX}`
      )
    }
  }

  const { statusCode } = constants

  $axios.onError((error) => {
    const code = parseInt(error.response && error.response.status)
    if (code >= statusCode.server) {
      store.dispatch('common/SET_INTERNAL_ERROR', code)
    }

    if (code === statusCode.client) {
      store.dispatch('auth/USER_LOGOUT')
    }
  })
}
