import { arrSortMinMax } from '@/dependencies/helpers/arr-sorter'

export const SET_USER_DATA_MUTATION = 'SET_USER_DATA_MUTATION'
export const SET_USER_ACHIEVEMENTS_MUTATION = 'SET_USER_ACHIEVEMENTS_MUTATION'
export const SET_USER_CONTACTS_MUTATION = 'SET_USER_CONTACTS_MUTATION'
export const SET_USER_AVATAR_MUTATION = 'SET_USER_AVATAR_MUTATION'
export const USER_LOGOUT_MUTATION = 'USER_LOGOUT_MUTATION'
export const SET_USER_SOCIAL_AUTH_MUTATION = 'SET_USER_SOCIAL_AUTH_MUTATION'
export const SET_USER_WORKPLACES_MUTATION = 'SET_USER_WORKPLACES_MUTATION'
export const SET_USER_SOCIAL_NETWORKS_MUTATION =
  'SET_USER_SOCIAL_NETWORKS_MUTATION'
export const REMOVE_USER_SPORT_MUTATION = 'REMOVE_USER_SPORT_MUTATION'
export const REMOVE_USER_WORKPLACE_MUTATION = 'REMOVE_USER_WORKPLACE_MUTATION'
export const REMOVE_USER_TRAINING_MUTATION = 'REMOVE_USER_TRAINING_MUTATION'

export default {
  [USER_LOGOUT_MUTATION]() {
    this.$auth.logout()
  },

  [SET_USER_DATA_MUTATION](state, payload) {
    const { authNuxt } = this.state

    const {
      user: { sports }
    } = authNuxt

    sports.forEach((el, idx) => (el.value = payload.sports[idx].value))

    delete payload.status
    delete payload.sports
    authNuxt.user = Object.assign({}, authNuxt.user, payload)
  },

  [SET_USER_ACHIEVEMENTS_MUTATION](state, payload) {
    const { authNuxt } = this.state
    authNuxt.user.achievements = payload
  },

  [SET_USER_CONTACTS_MUTATION](state, payload) {
    const { authNuxt } = this.state
    authNuxt.user.phones = payload.phones
    authNuxt.user.site = payload.site
    authNuxt.user.emails = payload.emails
  },

  [SET_USER_AVATAR_MUTATION](state, payload) {
    const { authNuxt } = this.state
    authNuxt.user.avatar = `${payload}?${Date.now()}`
    authNuxt.user.avatar_url = true
  },

  [SET_USER_WORKPLACES_MUTATION](state, payload) {
    const { sport, idx } = payload
    const { authNuxt } = this.state
    sport.clubs = arrSortMinMax(sport.clubs, 'id')
    authNuxt.user.sports[idx] = sport
  },

  [SET_USER_SOCIAL_NETWORKS_MUTATION](state, payload) {
    const { authNuxt } = this.state
    authNuxt.user.social_networks = payload
  },

  [SET_USER_SOCIAL_AUTH_MUTATION](state, payload) {
    state.socialAuth = payload
  },

  [REMOVE_USER_SPORT_MUTATION](state, payload) {
    const { authNuxt } = this.state

    authNuxt.user.sports = authNuxt.user.sports.filter(
      (el) => el.trainer_sport_id !== payload
    )
  },

  [REMOVE_USER_WORKPLACE_MUTATION](state, payload) {
    const {
      authNuxt: {
        user: { sports }
      }
    } = this.state
    const { id, idx } = payload

    sports[idx].clubs = sports[idx].clubs.filter((el) => el.id !== id)
  },

  [REMOVE_USER_TRAINING_MUTATION](state, payload) {
    const {
      authNuxt: {
        user: { sports }
      }
    } = this.state

    const { id, idx, workIdx } = payload

    sports[idx].clubs[workIdx].trainings = sports[idx].clubs[
      workIdx
    ].trainings.filter((el) => el.id !== id)
  }
}
