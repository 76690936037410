var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"burger-container"},[_c('div',{ref:"burger",attrs:{"id":"nav-icon"},on:{"click":_vm.toggleFunc}},[_c('span'),_vm._v(" "),_c('span'),_vm._v(" "),_c('span'),_vm._v(" "),_c('span')]),_vm._v(" "),_c('el-dialog',{staticClass:"navigation",attrs:{"visible":_vm.show,"width":"100%","modal":false,"append-to-body":true,"before-close":_vm.toggleFunc},on:{"update:visible":function($event){_vm.show=$event}}},[_c('div',{staticClass:"header-block"},[_vm._v("\n      "+_vm._s(_vm.$t('common.menu'))+"\n    ")]),_vm._v(" "),_c('ul',[_c('li',[_c('nuxt-link',{attrs:{"to":_vm.localePath({
              name: 'location-profile-index',
              params: { location: _vm.city }
            })},nativeOn:{"click":function($event){return _vm.toggleFunc.apply(null, arguments)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('common.about_me')))])])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.localePath({
              name: 'location-profile-index-workplaces',
              params: { location: _vm.city }
            })},nativeOn:{"click":function($event){return _vm.toggleFunc.apply(null, arguments)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('common.workplace')))])])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.localePath({
              name: 'location-profile-index-achievements',
              params: { location: _vm.city }
            })},nativeOn:{"click":function($event){return _vm.toggleFunc.apply(null, arguments)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('common.achievements')))])])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.localePath({
              name: 'location-profile-index-contacts',
              params: { location: _vm.city }
            })},nativeOn:{"click":function($event){return _vm.toggleFunc.apply(null, arguments)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('common.contacts')))])])],1)]),_vm._v(" "),_c('ul',[_c('li',[_c('nuxt-link',{attrs:{"to":_vm.localePath({
              name: 'location-profile-index-password-change',
              params: { location: _vm.city }
            })},nativeOn:{"click":function($event){return _vm.toggleFunc.apply(null, arguments)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('common.change_password')))])])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.localePath({
              name: 'location-trainer-id',
              params: { id: _vm.userURL, location: _vm.city }
            })},nativeOn:{"click":function($event){return _vm.toggleFunc.apply(null, arguments)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('common.profile_view')))])])],1)]),_vm._v(" "),_c('ul',[_c('li',[_c('button',{staticClass:"exit-button",on:{"click":_vm.logoutUser}},[_vm._v("\n          "+_vm._s(_vm.$t('common.exit'))+"\n        ")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }