import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _384bf29d = () => interopDefault(import('../pages/_location/404.vue' /* webpackChunkName: "" */))
const _78cf75f7 = () => interopDefault(import('../pages/_location/index.vue' /* webpackChunkName: "pages/_location/index" */))
const _69731326 = () => interopDefault(import('../pages/_location/privacy.vue' /* webpackChunkName: "pages/_location/privacy" */))
const _e6aaafde = () => interopDefault(import('../pages/_location/profile/index.vue' /* webpackChunkName: "pages/_location/profile/index" */))
const _d9b9c458 = () => interopDefault(import('../pages/_location/profile/index/index.vue' /* webpackChunkName: "pages/_location/profile/index/index" */))
const _0afc1112 = () => interopDefault(import('../pages/_location/profile/index/achievements.vue' /* webpackChunkName: "pages/_location/profile/index/achievements" */))
const _800bf47e = () => interopDefault(import('../pages/_location/profile/index/contacts.vue' /* webpackChunkName: "pages/_location/profile/index/contacts" */))
const _42b180b8 = () => interopDefault(import('../pages/_location/profile/index/password-change.vue' /* webpackChunkName: "pages/_location/profile/index/password-change" */))
const _11cb718b = () => interopDefault(import('../pages/_location/profile/index/workplaces.vue' /* webpackChunkName: "pages/_location/profile/index/workplaces" */))
const _3f0e6637 = () => interopDefault(import('../pages/_location/restore/password/_.vue' /* webpackChunkName: "pages/_location/restore/password/_" */))
const _4254ebda = () => interopDefault(import('../pages/_location/search/trainers/_.vue' /* webpackChunkName: "pages/_location/search/trainers/_" */))
const _6436684c = () => interopDefault(import('../pages/_location/club/_id.vue' /* webpackChunkName: "pages/_location/club/_id" */))
const _6d067c76 = () => interopDefault(import('../pages/_location/trainer/_id.vue' /* webpackChunkName: "pages/_location/trainer/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _384bf29d,
    name: "404page___en"
  }, {
    path: "/es",
    component: _384bf29d,
    name: "404page___es"
  }, {
    path: "/lt",
    component: _384bf29d,
    name: "404page___lt"
  }, {
    path: "/pl",
    component: _384bf29d,
    name: "404page___pl"
  }, {
    path: "/ru",
    component: _384bf29d,
    name: "404page___ru"
  }, {
    path: "/uk",
    component: _384bf29d,
    name: "404page___uk"
  }, {
    path: "/en/:location",
    component: _78cf75f7,
    name: "location___en"
  }, {
    path: "/es/:location",
    component: _78cf75f7,
    name: "location___es"
  }, {
    path: "/lt/:location",
    component: _78cf75f7,
    name: "location___lt"
  }, {
    path: "/pl/:location",
    component: _78cf75f7,
    name: "location___pl"
  }, {
    path: "/ru/:location",
    component: _78cf75f7,
    name: "location___ru"
  }, {
    path: "/uk/:location",
    component: _78cf75f7,
    name: "location___uk"
  }, {
    path: "/en/:location?/404",
    component: _384bf29d,
    name: "location-404___en"
  }, {
    path: "/en/:location?/privacy",
    component: _69731326,
    name: "location-privacy___en"
  }, {
    path: "/en/:location?/profile",
    component: _e6aaafde,
    children: [{
      path: "",
      component: _d9b9c458,
      name: "location-profile-index___en"
    }, {
      path: "achievements",
      component: _0afc1112,
      name: "location-profile-index-achievements___en"
    }, {
      path: "contacts",
      component: _800bf47e,
      name: "location-profile-index-contacts___en"
    }, {
      path: "password-change",
      component: _42b180b8,
      name: "location-profile-index-password-change___en"
    }, {
      path: "workplaces",
      component: _11cb718b,
      name: "location-profile-index-workplaces___en"
    }]
  }, {
    path: "/es/:location?/404",
    component: _384bf29d,
    name: "location-404___es"
  }, {
    path: "/es/:location?/privacy",
    component: _69731326,
    name: "location-privacy___es"
  }, {
    path: "/es/:location?/profile",
    component: _e6aaafde,
    children: [{
      path: "",
      component: _d9b9c458,
      name: "location-profile-index___es"
    }, {
      path: "achievements",
      component: _0afc1112,
      name: "location-profile-index-achievements___es"
    }, {
      path: "contacts",
      component: _800bf47e,
      name: "location-profile-index-contacts___es"
    }, {
      path: "password-change",
      component: _42b180b8,
      name: "location-profile-index-password-change___es"
    }, {
      path: "workplaces",
      component: _11cb718b,
      name: "location-profile-index-workplaces___es"
    }]
  }, {
    path: "/lt/:location?/404",
    component: _384bf29d,
    name: "location-404___lt"
  }, {
    path: "/lt/:location?/privacy",
    component: _69731326,
    name: "location-privacy___lt"
  }, {
    path: "/lt/:location?/profile",
    component: _e6aaafde,
    children: [{
      path: "",
      component: _d9b9c458,
      name: "location-profile-index___lt"
    }, {
      path: "achievements",
      component: _0afc1112,
      name: "location-profile-index-achievements___lt"
    }, {
      path: "contacts",
      component: _800bf47e,
      name: "location-profile-index-contacts___lt"
    }, {
      path: "password-change",
      component: _42b180b8,
      name: "location-profile-index-password-change___lt"
    }, {
      path: "workplaces",
      component: _11cb718b,
      name: "location-profile-index-workplaces___lt"
    }]
  }, {
    path: "/pl/:location?/404",
    component: _384bf29d,
    name: "location-404___pl"
  }, {
    path: "/pl/:location?/privacy",
    component: _69731326,
    name: "location-privacy___pl"
  }, {
    path: "/pl/:location?/profile",
    component: _e6aaafde,
    children: [{
      path: "",
      component: _d9b9c458,
      name: "location-profile-index___pl"
    }, {
      path: "achievements",
      component: _0afc1112,
      name: "location-profile-index-achievements___pl"
    }, {
      path: "contacts",
      component: _800bf47e,
      name: "location-profile-index-contacts___pl"
    }, {
      path: "password-change",
      component: _42b180b8,
      name: "location-profile-index-password-change___pl"
    }, {
      path: "workplaces",
      component: _11cb718b,
      name: "location-profile-index-workplaces___pl"
    }]
  }, {
    path: "/ru/:location?/404",
    component: _384bf29d,
    name: "location-404___ru"
  }, {
    path: "/ru/:location?/privacy",
    component: _69731326,
    name: "location-privacy___ru"
  }, {
    path: "/ru/:location?/profile",
    component: _e6aaafde,
    children: [{
      path: "",
      component: _d9b9c458,
      name: "location-profile-index___ru"
    }, {
      path: "achievements",
      component: _0afc1112,
      name: "location-profile-index-achievements___ru"
    }, {
      path: "contacts",
      component: _800bf47e,
      name: "location-profile-index-contacts___ru"
    }, {
      path: "password-change",
      component: _42b180b8,
      name: "location-profile-index-password-change___ru"
    }, {
      path: "workplaces",
      component: _11cb718b,
      name: "location-profile-index-workplaces___ru"
    }]
  }, {
    path: "/uk/:location?/404",
    component: _384bf29d,
    name: "location-404___uk"
  }, {
    path: "/uk/:location?/privacy",
    component: _69731326,
    name: "location-privacy___uk"
  }, {
    path: "/uk/:location?/profile",
    component: _e6aaafde,
    children: [{
      path: "",
      component: _d9b9c458,
      name: "location-profile-index___uk"
    }, {
      path: "achievements",
      component: _0afc1112,
      name: "location-profile-index-achievements___uk"
    }, {
      path: "contacts",
      component: _800bf47e,
      name: "location-profile-index-contacts___uk"
    }, {
      path: "password-change",
      component: _42b180b8,
      name: "location-profile-index-password-change___uk"
    }, {
      path: "workplaces",
      component: _11cb718b,
      name: "location-profile-index-workplaces___uk"
    }]
  }, {
    path: "/es/:location?/restore/password/*",
    component: _3f0e6637,
    name: "location-restore-password-all___es"
  }, {
    path: "/pl/:location?/restore/password/*",
    component: _3f0e6637,
    name: "location-restore-password-all___pl"
  }, {
    path: "/lt/:location?/restore/password/*",
    component: _3f0e6637,
    name: "location-restore-password-all___lt"
  }, {
    path: "/uk/:location?/restore/password/*",
    component: _3f0e6637,
    name: "location-restore-password-all___uk"
  }, {
    path: "/ru/:location?/restore/password/*",
    component: _3f0e6637,
    name: "location-restore-password-all___ru"
  }, {
    path: "/en/:location?/restore/password/*",
    component: _3f0e6637,
    name: "location-restore-password-all___en"
  }, {
    path: "/es/:location?/search/trainers/*",
    component: _4254ebda,
    name: "location-search-trainers-all___es"
  }, {
    path: "/pl/:location?/search/trainers/*",
    component: _4254ebda,
    name: "location-search-trainers-all___pl"
  }, {
    path: "/lt/:location?/search/trainers/*",
    component: _4254ebda,
    name: "location-search-trainers-all___lt"
  }, {
    path: "/uk/:location?/search/trainers/*",
    component: _4254ebda,
    name: "location-search-trainers-all___uk"
  }, {
    path: "/ru/:location?/search/trainers/*",
    component: _4254ebda,
    name: "location-search-trainers-all___ru"
  }, {
    path: "/en/:location?/search/trainers/*",
    component: _4254ebda,
    name: "location-search-trainers-all___en"
  }, {
    path: "/en/:location?/club/:id?",
    component: _6436684c,
    name: "location-club-id___en"
  }, {
    path: "/en/:location?/trainer/:id?",
    component: _6d067c76,
    name: "location-trainer-id___en"
  }, {
    path: "/es/:location?/club/:id?",
    component: _6436684c,
    name: "location-club-id___es"
  }, {
    path: "/es/:location?/trainer/:id?",
    component: _6d067c76,
    name: "location-trainer-id___es"
  }, {
    path: "/lt/:location?/club/:id?",
    component: _6436684c,
    name: "location-club-id___lt"
  }, {
    path: "/lt/:location?/trainer/:id?",
    component: _6d067c76,
    name: "location-trainer-id___lt"
  }, {
    path: "/pl/:location?/club/:id?",
    component: _6436684c,
    name: "location-club-id___pl"
  }, {
    path: "/pl/:location?/trainer/:id?",
    component: _6d067c76,
    name: "location-trainer-id___pl"
  }, {
    path: "/ru/:location?/club/:id?",
    component: _6436684c,
    name: "location-club-id___ru"
  }, {
    path: "/ru/:location?/trainer/:id?",
    component: _6d067c76,
    name: "location-trainer-id___ru"
  }, {
    path: "/uk/:location?/club/:id?",
    component: _6436684c,
    name: "location-club-id___uk"
  }, {
    path: "/uk/:location?/trainer/:id?",
    component: _6d067c76,
    name: "location-trainer-id___uk"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
