import constants from '@/dependencies/constants'

export default class DomainSelector {
  static GetDomain(url) {
    const { DOMAIN, DOMAIN_NAME, FOOTER_INFO } = constants
    const urlSplit = url.split('.')

    const domain = urlSplit[urlSplit.length - 1]

    switch (domain) {
      case DOMAIN.PL:
      case DOMAIN.EE:
      case DOMAIN.LV:
      case DOMAIN.LT:
      case DOMAIN.BY:
      case DOMAIN.UA: {
        return {
          name: domain,
          title: DOMAIN_NAME[domain],
          footer: FOOTER_INFO[domain]
        }
      }
      default: {
        return { name: 'ua', title: DOMAIN_NAME.ua, footer: FOOTER_INFO.ua }
      }
    }
  }
  isPLDomain(url) {
    const { DOMAIN } = constants
    const urlSplit = url.split('.')
    const domain = urlSplit[urlSplit.length - 1]
    return domain === DOMAIN.PL
  }
}
