export const SET_CITY_NAME_MUTATION = 'SET_CITY_NAME_MUTATION'
export const SET_DOMAIN_TITLE_MUTATION = 'SET_DOMAIN_TITLE_MUTATION'
export const SET_PAGE_TITLE_MUTATION = 'SET_PAGE_TITLE'
export const SET_PAGE_META_MUTATION = 'SET_PAGE_META'

export default {
  [SET_CITY_NAME_MUTATION](state, payload) {
    state.cityLocation.name = payload
  },

  [SET_DOMAIN_TITLE_MUTATION](state, payload) {
    state.cityLocation.domainTitle = payload
  },

  [SET_PAGE_META_MUTATION](state, meta) {
    state.meta = meta
  },

  [SET_PAGE_TITLE_MUTATION](state, title) {
    state.title = title
  }
}
