// Mixin to inflect cities names in Russian & Ukrainian

export default {
  computed: {
    lang() {
      return this.$i18n.locale
    }
  },
  data: () => ({
    nativeLangs: ['uk', 'ru']
  }),
  methods: {
    cityNameInflect(_city) {
      const { alternate_name, url_key } = _city

      return this.nativeLangs.includes(this.lang)
        ? this.$t(`inflect_city_names.${url_key}`)
        : alternate_name
    }
  }
}
