import createPersistedState from 'vuex-persistedstate'

export default ({ store }) => {
  window.onNuxtReady(() => {
    createPersistedState({
      key: 'dict',
      paths: [
        'dict.allSports',
        'dict.socialNetworks',
        'dict.allFirstLetters',
        'location.popularCities',
        'seo.cityLocation',
        'search.location',
        'search.subwayLines',
        'user.footerInfo',
        'location.city_name'
      ]
    })(store)
    createPersistedState({
      key: 'search',
      paths: [
        'search.searchAge',
        'search.searchDistr',
        'search.searchMicroDistrict',
        'search.searchSubway',
        'search.searchGender',
        'search.searchCost',
        'search.searchSchedule',
        'search.searchScheduleStr',
        'search.outdoor',
        'search.sportType',
        'search.sportClub',
        'search.bookmarks',
        'search.bookmarksNumber',
        'search.cityDistricts',
        'search.location',
        'search.commonSubwayStationsList'
      ]
    })(store)
    createPersistedState({
      key: 'common',
      paths: ['common.isMobile']
    })(store)
  })
}
