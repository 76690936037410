export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"code":"en","iso":"en-US","file":"en-US.js"},{"code":"ru","iso":"ru-RU","file":"ru-RU.js"},{"code":"uk","iso":"uk-UK","file":"uk-UK.js"},{"code":"lt","iso":"lt-LT","file":"lt-LT.js"},{"code":"pl","iso":"pl-PL","file":"pl-PL.js"},{"code":"es","iso":"es-ES","file":"es-ES.js"}],
  defaultLocale: "ru",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix",
  lazy: true,
  langDir: "/usr/src/app/lang",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: false,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","iso":"en-US","file":"en-US.js"},{"code":"ru","iso":"ru-RU","file":"ru-RU.js"},{"code":"uk","iso":"uk-UK","file":"uk-UK.js"},{"code":"lt","iso":"lt-LT","file":"lt-LT.js"},{"code":"pl","iso":"pl-PL","file":"pl-PL.js"},{"code":"es","iso":"es-ES","file":"es-ES.js"}],
  localeCodes: ["en","ru","uk","lt","pl","es"],
}

export const localeMessages = {
  'en-US.js': () => import('../../lang/en-US.js' /* webpackChunkName: "lang-en-US.js" */),
  'ru-RU.js': () => import('../../lang/ru-RU.js' /* webpackChunkName: "lang-ru-RU.js" */),
  'uk-UK.js': () => import('../../lang/uk-UK.js' /* webpackChunkName: "lang-uk-UK.js" */),
  'lt-LT.js': () => import('../../lang/lt-LT.js' /* webpackChunkName: "lang-lt-LT.js" */),
  'pl-PL.js': () => import('../../lang/pl-PL.js' /* webpackChunkName: "lang-pl-PL.js" */),
  'es-ES.js': () => import('../../lang/es-ES.js' /* webpackChunkName: "lang-es-ES.js" */),
}
