export default class CurrencySelector {
  static GetCurrency(url) {
    const urlSplit = url.split('.')

    const domain = urlSplit[urlSplit.length - 1]

    switch (domain) {
      case 'lv':
      case 'lt':
      case 'ee': {
        return {
          value: 'currency.euro',
          currencySign: '€',
          icon: 'icon-Eu'
        }
      }
      case 'pl': {
        return {
          value: 'currency.pl',
          currencySign: 'zł',
          icon: 'icon-Zl'
        }
      }
      case 'ua': {
        return {
          value: 'currency.ua',
          currencySign: '₴',
          icon: 'icon-price'
        }
      }
      case 'by': {
        return {
          value: 'currency.by',
          currencySign: 'Br',
          icon: 'icon-Br'
        }
      }
      default:
        return {
          value: 'currency.ua',
          currencySign: '₴',
          icon: 'icon-price'
        }
    }
  }
}
