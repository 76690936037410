export default () => ({
  sportType: null,
  sportClub: null,
  searchDistr: [],
  searchMicroDistrict: [],
  searchSubway: [],
  searchGender: 0,
  searchAge: null,
  searchCost: null,
  searchSchedule: null,
  searchScheduleStr: '',
  location: null,
  outdoor: false,
  sort: { field: 'name', direction: 'asc' },
  bookmarks: {},
  bookmarksNumber: 0,
  isBookmarksShown: false,
  trainersList: [],
  pagesNumber: null,
  searchPage: 1,
  seo: null,
  meta: null,
  subwayLines: null,
  subwayStations: null,
  clubsList: null,
  subwayMap: null,
  subwayLinesMap: {},
  commonSubwayStationsList: {},
  cityDistricts: [],
  emptyTrainersList: false,
  cachedTrainers: null,
  focusedTrainer: null,
  resetFiltersStatus: false,
  requestStatus: false
})
