//  mutation name starts with 'SET' if it changes
//  property which value must change trainers list
export const SET_SPORT_TYPE = 'SET_SPORT_TYPE'
export const SET_SPORT_CLUB = 'SET_SPORT_CLUB'
export const SET_DISTRICT = 'SET_DISTRICT'
export const SET_MICRO_DISTRICT = 'SET_MICRO_DISTRICT'
export const SET_SUBWAY = 'SET_SUBWAY'
export const SET_GENDER = 'SET_GENDER'
export const SET_AGE = 'SET_AGE'
export const SET_COST = 'SET_COST'
export const SET_SCHEDULE = 'SET_SCHEDULE'
export const SCHEDULE_STR = 'SCHEDULE_STR'
export const SET_OUTDOOR = 'SET_OUTDOOR'
export const CHANGE_LOCATION = 'CHANGE_LOCATION'
export const CHANGE_CURRENT_PAGE = 'CHANGE_CURRENT_PAGE'
export const SET_SORT_METHOD = 'SET_SORT_METHOD'
export const ADD_BOOKMARK = 'ADD_BOOKMARK'
export const DELETE_BOOKMARK = 'DELETE_BOOKMARK'
export const LOAD_TRAINERS_LIST = 'LOAD_TRAINERS_LIST'
export const LOAD_META = 'LOAD_META'
export const LOAD_SEO = 'LOAD_SEO'
export const SHOW_BOOKMARKS = 'SHOW_BOOKMARKS'
export const LOAD_SUBWAY_LINES = 'LOAD_SUBWAY_LINES'
export const LOAD_SUBWAY_STATIONS = 'LOAD_SUBWAY_STATIONS'
export const RESET_FILTERS = 'RESET_FILTERS'
export const CLEAR_FILTERS_MUTATION = 'CLEAR_FILTERS'
export const LOAD_CLUBS_LIST = 'LOAD_CLUBS_LIST'
export const LOAD_SUBWAY_MAP_MUTATION = 'LOAD_SUBWAY_MAP'
export const CLEAR_BEFORE_NEW_QUERY = 'CLEAR_BEFORE_NEW_QUERY'
export const CLEAR_BEFORE_LOCATION_CHANGE = 'CLEAR_BEFORE_LOCATION_CHANGE'
export const LOAD_CITY_DISTRICTS_MUTATION = 'LOAD_CITY_DISTRICTS_MUTATION'
export const SET_DISTRICTS_CLEAR = 'SET_DISTRICTS_CLEAR'
export const SET_ALL_DISTRICTS = 'SET_ALL_DISTRICTS'
export const LOAD_TRAINERS_CLUBS = 'LOAD_TRAINERS_CLUBS'
export const EMPTY_TRAINERS_LIST_MUTATION = 'EMPTY_TRAINERS_LIST'
export const CACHE_TRAINERS_MUTATION = 'CACHE_TRAINERS'
export const FOCUS_TRAINER_MUTATION = 'FOCUS_TRAINER'
export const FILL_DISTRICTS_NAMES_MUTATION = 'FILL_DISTRICTS_NAMES'
export const CHANGE_FILTERS_STATUS_MUTATION = 'CHANGE_FILTERS_STATUS_MUTATION'
export const CHANGE_REQUEST_STATUS_MUTATION = 'CHANGE_REQUEST_STATUS_MUTATION'

const resetFilters = (state) => {
  state.searchDistr = []
  state.searchMicroDistrict = []
  state.searchSubway = []
  state.searchGender = 0
  state.searchAge = null
  state.searchCost = null
  state.searchSchedule = null
  state.searchScheduleStr = null
  state.outdoor = false
  state.searchPage = 1
}

export default {
  [SET_SPORT_TYPE](state, sportType) {
    state.sportClub = null
    state.sportType = sportType
  },
  [SET_SPORT_CLUB](state, sportClub) {
    state.sportType = null
    state.sportClub = sportClub
  },
  [SET_DISTRICT](state, searchDistrict) {
    if (state.searchSubway?.length) {
      state.searchSubway = []
    }
    state.searchDistr = searchDistrict
  },
  [SET_MICRO_DISTRICT](state, searchMicroDistrict) {
    if (state.searchSubway?.length) {
      state.searchSubway = []
    }
    state.searchMicroDistrict = searchMicroDistrict
  },
  [SET_ALL_DISTRICTS](state, districtsObj) {
    const { districts, microdistricts } = districtsObj
    if (state.searchSubway?.length) {
      state.searchSubway = []
    }
    state.searchDistr = districts
    state.searchMicroDistrict = microdistricts
  },
  [SET_DISTRICTS_CLEAR](state) {
    //  mutation which clean microdistricts
    //  and districts (for reset button in filters)
    state.searchMicroDistrict = []
    state.searchDistr = []
  },
  [FILL_DISTRICTS_NAMES_MUTATION](state) {
    //  fill empty districts names based on their IDs

    const microDistrictsList = state.cityDistricts
      .reduce((acc, district) => {
        return [...acc, ...district.micro_districts]
      }, [])
      .reduce((acc, district) => {
        return { ...acc, [district.id]: district }
      }, {})

    const districtList = state.cityDistricts.reduce((acc, district) => {
      return { ...acc, [district.id]: district }
    }, {})

    state.searchDistr = state.searchDistr.map((district) => {
      return {
        title: districtList[district.id].title,
        id: district.id
      }
    })

    state.searchMicroDistrict = state.searchMicroDistrict.map((district) => {
      return {
        title: microDistrictsList[district.id].title,
        id: district.id
      }
    })
  },
  [SET_SUBWAY](state, searchSubway) {
    state.searchDistr = []
    state.searchMicroDistrict = []
    state.searchSubway = searchSubway
  },
  [SET_GENDER](state, searchGender) {
    state.searchGender = searchGender
  },
  [SET_AGE](state, searchAge) {
    state.searchAge = searchAge
  },
  [SET_COST](state, searchCost) {
    state.searchCost = searchCost
  },
  [SET_SCHEDULE](state, searchSchedule) {
    state.searchSchedule = searchSchedule
  },
  [SCHEDULE_STR](state, searchScheduleStr) {
    state.searchScheduleStr = searchScheduleStr
  },
  [SET_OUTDOOR](state, outdoor) {
    state.outdoor = outdoor
  },
  [CHANGE_LOCATION](state, location) {
    state.location = location
  },
  [CHANGE_CURRENT_PAGE](state, page) {
    state.searchPage = page
  },
  [SET_SORT_METHOD](state, sortMethod) {
    state.sort = sortMethod
  },
  [ADD_BOOKMARK](state, bookmark) {
    if (bookmark) {
      state.bookmarks[bookmark.id] = bookmark
      state.bookmarksNumber = Object.keys(state.bookmarks).length
    }
  },
  [DELETE_BOOKMARK](state, bookmarkId) {
    delete state.bookmarks[bookmarkId]
    state.bookmarksNumber = Object.keys(state.bookmarks).length
  },
  [LOAD_TRAINERS_LIST](state, list) {
    state.trainersList = list
  },
  [SHOW_BOOKMARKS](state, bool) {
    state.isBookmarksShown = bool
  },
  [LOAD_META](state, meta) {
    state.meta = meta
  },
  [LOAD_SEO](state, seo) {
    state.seo = seo
  },

  [RESET_FILTERS](state) {
    resetFilters(state)
  },

  [CLEAR_FILTERS_MUTATION](state) {
    resetFilters(state)
    state.sportClub = null
    state.sportType = null
  },

  [LOAD_CLUBS_LIST](state, clubsList) {
    state.clubsList = clubsList
  },
  [LOAD_SUBWAY_LINES](state, lines) {
    //  reset loaded subway stations before saving new lines
    state.subwayStations = null

    const linesObj = {}
    for (const line in lines) {
      linesObj[lines[line].id] = lines[line]
    }
    state.subwayLinesMap = linesObj
    state.subwayLines = lines.sort((a, b) => b.id - a.id)
  },
  [LOAD_SUBWAY_STATIONS](state, obj) {
    if (!obj) {
      state.commonSubwayStationsList = {}
      state.subwayStations = null
      state.subwayLines = null
      return
    }
    const commonList = {}
    const res = {}

    const { stations, lineID } = obj
    res[lineID] = stations

    for (const item in stations) {
      commonList[stations[item].id] = stations[item]
    }
    state.commonSubwayStationsList = {
      ...state.commonSubwayStationsList,
      ...commonList
    }

    state.subwayStations = {
      ...state.subwayStations,
      ...res
    }
  },
  [LOAD_SUBWAY_MAP_MUTATION](state, payload) {
    state.subwayMap = payload
  },
  [CLEAR_BEFORE_NEW_QUERY](state, isMobile) {
    state.trainersList = []
    state.clubsList = null
    //  if mobile view don't clean
    if (!isMobile) {
      state.seo = null
      state.meta = null
    }
    state.emptyTrainersList = false
  },
  [CLEAR_BEFORE_LOCATION_CHANGE](state) {
    state.searchPage = 1
    state.searchDistr = []
    state.searchMicroDistrict = []
    state.searchSubway = []
    state.sportClub = null
    state.sportType = null
    resetFilters(state)
  },
  [LOAD_CITY_DISTRICTS_MUTATION](state, payload) {
    state.cityDistricts = payload
  },
  [EMPTY_TRAINERS_LIST_MUTATION](state, val) {
    state.emptyTrainersList = val
  },
  [CACHE_TRAINERS_MUTATION](state, cachedTrainers) {
    state.cachedTrainers = cachedTrainers
  },
  [FOCUS_TRAINER_MUTATION](state, focusedID) {
    state.focusedTrainer = focusedID
  },

  [CHANGE_FILTERS_STATUS_MUTATION](state, payload) {
    state.resetFiltersStatus = payload
  },

  [CHANGE_REQUEST_STATUS_MUTATION](state, payload) {
    state.requestStatus = payload
  }
}
