import constants from '@/dependencies/constants'
import BaseApi from '@/api/base'

export default class DictApi extends BaseApi {
  allSports(config) {
    return this.$axios.get(constants.API.ROUTES.DICT.ALL_SPORTS, config)
  }

  getCities(params, _lang) {
    this.$axios.setHeader('Content-Language', _lang)
    return this.$axios.get(constants.API.ROUTES.DICT.GET_CITIES, params)
  }

  getClubs(params) {
    return this.$axios.get(constants.API.ROUTES.DICT.GET_CLUBS, params)
  }

  getInstitutions(params) {
    return this.$axios.post(constants.API.ROUTES.DICT.GET_INSTITUTIONS, params)
  }

  getSocialNetworks() {
    return this.$axios.get(constants.API.ROUTES.COMMENT.GET_SOCIAL_NETWORKS)
  }
}
