import { SET_DOMAIN_INFO_MUTATION } from './mutations'
import PhoneMasker from '@/dependencies/helpers/phone-mask'
import CurrencySelector from '@/dependencies/helpers/currency-selector'
import DomainSelector from '@/dependencies/helpers/domain-selector'

export const LOAD_DOMAIN_INFORMATION_ACTION = 'LOAD_DOMAIN_INFORMATION'

export default {
  [LOAD_DOMAIN_INFORMATION_ACTION]({ commit }) {
    const { hostname } = window.location

    const phoneMask = new PhoneMasker.GetMask(hostname)
    const currObj = new CurrencySelector.GetCurrency(hostname)
    const { footer } = new DomainSelector.GetDomain(hostname)

    commit(SET_DOMAIN_INFO_MUTATION, { phoneMask, currObj, footer })

    return Promise.resolve()
  }
}
