export default () => ({
  error: null,
  internalServError: null,
  feedbackData: {
    text: '',
    rating: null,
    filter: null
  },
  isMobile: false
})
