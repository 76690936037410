import {
  MUTATION_SET_ALL_SPORTS,
  MUTATION_SET_ALL_FIRST_LETTERS,
  MUTATION_SET_SOCIAL_NETWORKS,
  MUTATION_RESET_ALL_SPORTS
} from './mutations'

export const LOAD_ALL_SPORTS_ACTION = 'LOAD_ALL_SPORTS'
export const LOAD_ALL_SOCIAL_NETWORKS = 'LOAD_ALL_SOC_NETWORKS'
export const LOAD_CLASSIFIERS_ACTION = 'LOAD_CLASSIFIERS'

export default {
  [LOAD_CLASSIFIERS_ACTION]({ dispatch }) {
    return Promise.all([
      dispatch('location/LOAD_POPULAR_CITIES', null, { root: true }),
      dispatch(LOAD_ALL_SPORTS_ACTION),
      dispatch(LOAD_ALL_SOCIAL_NETWORKS),
      dispatch('search/LOAD_SUBWAY_MAP', null, { root: true }),
      dispatch('search/LOAD_SUBWAY_LINES', null, { root: true })
    ])
  },

  async [LOAD_ALL_SPORTS_ACTION]({ commit, state, rootState }, payload) {
    if (state?.allSports?.length && !payload) return

    commit(MUTATION_RESET_ALL_SPORTS)

    const lang = payload || this.$i18n.locale

    const config = {
      headers: { 'Content-Language': lang }
    }

    try {
      const {
        data: { data: allSports }
      } = await this.$api.DictApi.allSports(config)

      commit(MUTATION_SET_ALL_FIRST_LETTERS, allSports)
      commit(MUTATION_SET_ALL_SPORTS, allSports)

      return await Promise.resolve()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  },

  async [LOAD_ALL_SOCIAL_NETWORKS]({ commit, state }) {
    if (state?.socialNetworks?.length) return
    try {
      const {
        data: { data: networks }
      } = await this.$api.DictApi.getSocialNetworks()

      commit(MUTATION_SET_SOCIAL_NETWORKS, networks)

      return await Promise.resolve()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }
}
