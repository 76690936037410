export default {
  computed: {
    isLogged() {
      return this.$auth.loggedIn
    }
  },
  methods: {
    goHome() {
      this.$router.push(
        this.localePath({
          name: 'location',
          params: { location: this.city }
        })
      )
    }
  }
}
