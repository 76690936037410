<template>
  <div class="dropdown-select language">
    <el-select
      v-model="selectedLang"
      :popper-append-to-body="false"
      :value="selectedLang"
      @change="selectLang(selectedLang)"
    >
      <el-option
        v-for="lang in langs"
        :key="lang.name"
        :label="lang.name"
        :value="lang.value"
      />
      <span slot="prefix">{{ langName }}</span>
    </el-select>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import constants from '@/dependencies/constants'
import SetCookieMixin from '@/dependencies/mixins/set-cookie'

export default {
  name: 'DropdownLang',
  mixins: [SetCookieMixin],
  data() {
    return {
      selectedLang: this.$i18n.locale,
      langs: constants.LANGUAGES
    }
  },
  computed: {
    user() {
      return this.$auth.user
    },
    langName() {
      return this.langs?.find((el) => el.value === this.selectedLang)?.name
    }
  },
  watch: {
    async selectedLang() {
      await Promise.all([
        this.setAllSports(this.selectedLang),
        this.loadProfile(this.selectedLang),
        this.loadPopularCities(this.selectedLang),
        this.reloadCity(this.selectedLang),
        this.setCookie('locale', this.selectedLang)
      ])
    }
  },
  created() {
    this.selectLang(this.$i18n.locale)
  },
  methods: {
    ...mapActions({
      setAllSports: 'dict/LOAD_ALL_SPORTS',
      loadUser: 'auth/LOAD_USER_DATA',
      loadPopularCities: 'location/LOAD_POPULAR_CITIES',
      loadCity: 'location/LOAD_CITY'
    }),

    selectLang(lang) {
      this.$router.push(this.switchLocalePath(lang))
    },

    async loadProfile(_lang) {
      if (this.user) {
        await this.loadUser(_lang)
      }
    },

    async reloadCity(_lang) {
      const {
        params: { location: title }
      } = this.$route

      await this.loadCity({ title, newLang: _lang })
    }
  }
}
</script>

<style lang="scss" scoped></style>
