import {
  SET_FEEDBACK_FILTER_MUTATION,
  SET_FEEDBACK_BODY_MUTATION,
  SET_FEEDBACK_RATING_MUTATION,
  SET_WIDTH_MUTATION,
  RESET_FEEDBACK_DATA_MUTATION,
  SET_ERROR_MESSAGE_MUTATION,
  SET_INTERNAL_SERVER_ERROR_MUTATION
} from './mutations'

export const SET_ERROR_MESSAGE_ACTION = 'SET_ERROR_MESSAGE'
export const SET_FEEDBACK_BODY_ACTION = 'SET_FEEDBACK_BODY'
export const SET_FEEDBACK_FILTER_ACTION = 'SET_FEEDBACK_FILTER'
export const SET_FEEDBACK_RATING_ACTION = 'SET_FEEDBACK_RATING'
export const RESET_FEEDBACK_DATA_ACTION = 'RESET_FEEDBACK_DATA'
export const SET_WIDTH_ACTION = 'SET_WIDTH'
export const SET_INTERNAL_ERROR_ACTION = 'SET_INTERNAL_ERROR'

export default {
  [SET_FEEDBACK_BODY_ACTION]({ commit }, payload) {
    commit(SET_FEEDBACK_BODY_MUTATION, payload)
    return Promise.resolve()
  },

  [SET_FEEDBACK_FILTER_ACTION]({ commit }, payload) {
    commit(SET_FEEDBACK_FILTER_MUTATION, payload)
    return Promise.resolve()
  },

  [SET_FEEDBACK_RATING_ACTION]({ commit }, payload) {
    commit(SET_FEEDBACK_RATING_MUTATION, payload)
    return Promise.resolve()
  },

  [RESET_FEEDBACK_DATA_ACTION]({ commit }) {
    commit(RESET_FEEDBACK_DATA_MUTATION)
    return Promise.resolve()
  },

  [SET_ERROR_MESSAGE_ACTION]({ commit }, payload) {
    commit(SET_ERROR_MESSAGE_MUTATION, payload)
    return Promise.resolve()
  },

  [SET_WIDTH_ACTION]({ commit }, width) {
    commit(SET_WIDTH_MUTATION, width)
    return Promise.resolve()
  },

  [SET_INTERNAL_ERROR_ACTION]({ commit }, payload) {
    commit(SET_INTERNAL_SERVER_ERROR_MUTATION, payload)
    return Promise.resolve()
  }
}
