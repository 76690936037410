<!-- Parameter "type" for switching suggest function
    1 - Institutions
    2 - All sports & clubs (main page)
    3 - All cities suggest
    4 - Workplace clubs
-->

<template>
  <div class="autocomplete-container">
    <vue-simple-suggest
      ref="suggestComponent"
      v-model="inputVal"
      :list="getList"
      :display-attribute="displayAttr"
      value-attribute="id"
      :filter-by-query="false"
      :min-length="minLength"
      :max-suggestions="50"
      :debounce="500"
      @select="handleSelect"
    >
      <input
        ref="input"
        v-model="inputVal"
        type="text"
        :placeholder="placeholder"
        @focus="handleFocus"
        @blur="handleBlur"
        @mouseenter="hovering = true"
        @mouseleave="hovering = false"
      />
      <div slot="suggestion-item" slot-scope="scope">
        <span v-html="boldSuggestion(scope)" />
      </div>
    </vue-simple-suggest>
    <i
      v-if="showClear && inputVal"
      class="el-icon-circle-close"
      @click="clear"
      @mouseenter="hovering = true"
      @mouseleave="hovering = false"
    />
    <i v-if="prefixClass" :class="prefixClass" class="prefixIcon" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueSimpleSuggest from '@/components/Common/VueSimpleSuggest/VueSimpleSuggest'

export default {
  name: 'AutocompleteCustom',
  components: {
    VueSimpleSuggest
  },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    displayAttr: {
      type: String,
      default: 'label'
    },
    model: {
      type: String,
      default: ''
    },
    type: {
      type: Number,
      default: 0
    },
    itemIdx: {
      type: Number,
      default: 0
    },
    prefixClass: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    minLength: 3,
    inputVal: '',
    hovering: false,
    focused: false
  }),
  computed: {
    ...mapGetters({
      location: 'search/searchLocation',
      domainLang: 'location/getDomainLang'
    }),
    showClear() {
      return this.focused || this.hovering
    }
  },
  watch: {
    inputVal(newVal) {
      this.inputVal = newVal
      this.$emit('input', newVal)
      if (!this.inputVal.length) {
        this.$refs.suggestComponent.clearSuggestions()
      }
    }
  },
  mounted() {
    if (this.model) {
      this.inputVal = this.model
    }
    this.$bus.$on('autocomplete.clear', () => {
      this.inputVal = ''
    })
  },
  methods: {
    handleBlur() {
      setTimeout(() => {
        this.focused = false
        this.hovering = false
      }, 100)
    },
    handleFocus() {
      this.focused = true
    },
    clear() {
      setTimeout(() => {
        this.focused = true
        this.hovering = true
        this.$refs.input.focus()
      }, 120)

      this.inputVal = ''
    },

    getList() {
      switch (this.type) {
        case 1: {
          return this.loadAllInstitutions(this.inputVal)
        }
        case 2: {
          return this.loadAllSportsClubs(this.inputVal)
        }
        case 3: {
          return this.loadAllCities(this.inputVal)
        }
        case 4: {
          return this.loadWorkplaceClubs(this.inputVal)
        }
        default:
          return []
      }
    },

    boldSuggestion(scope) {
      if (!scope) return scope

      const { suggestion, query } = scope

      const result = this.$refs.suggestComponent.displayProperty(suggestion)

      if (!query) return result

      const texts = query.split(/[\s-_/\\|.]/gm).filter((t) => !!t) || ['']
      try {
        return result.replace(
          new RegExp('(.*?)(' + texts.join('|') + ')(.*?)', 'gi'),
          '$1<b>$2</b>$3'
        )
      } catch (e) {
        this.$refs.suggestComponent.clearSuggestions()
      }
    },

    handleSelect(item) {
      this.$emit('select', { ...item, idx: this.itemIdx })
    },

    // Suggestions fetching functions
    async loadAllInstitutions(_query) {
      try {
        const { data } = await this.$api.DictApi.getInstitutions({
          phrase: _query
        })

        return data
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    },

    async loadAllSportsClubs(_query) {
      try {
        const {
          data: { data: res }
        } = await this.$api.SportApi.getSportsClubsList({
          params: {
            title: _query,
            city_id: this.location
          }
        })

        return res
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    },

    async loadAllCities(_query) {
      try {
        const {
          data: { data: cities }
        } = await this.$api.DictApi.getCities({
          params: {
            title: _query,
            'country-iso': this.domainLang
          }
        })

        return cities
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    },

    async loadWorkplaceClubs(_query) {
      try {
        const {
          data: { data: clubs }
        } = await this.$api.DictApi.getClubs({
          params: {
            title: _query,
            'country-iso': this.domainLang,
            limit: 40
          }
        })

        return clubs
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '~assets/css/variables';
.autocomplete-container {
  width: 100%;
  position: relative;

  i.el-icon-circle-close {
    position: absolute;
    right: 10px;
    top: 13px;
    z-index: 100;
    color: #dcdfe6;
    transition: 0.3s linear;

    &:hover {
      cursor: pointer;
      color: $gray;
      transition: 0.3s linear;
    }
  }

  .prefixIcon {
    position: absolute;
    top: 33%;
    left: 10px;
    font-size: 22px;
    font-weight: bold;
    color: $primary;
  }
}
</style>
