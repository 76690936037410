<template>
  <el-dialog
    class="password-recovery-modal"
    :visible.sync="show"
    width="100%"
    :center="true"
    @close="serverErrReset"
  >
    <ValidationObserver ref="observer">
      <div class="form-header">
        <span>{{ $t('common.password_recovery') }}</span>
      </div>
      <div class="form">
        <div class="form-line">
          <i class="icon-email-lined" />
          <Input
            v-model="email"
            rules="required|email"
            name="email"
            type="email"
            :is-validate="true"
            clearable
            :placeholder="$t('placeholder.enter_your_email')"
          />
        </div>
        <div class="buttons-wrapper">
          <el-button type="warning" class="enter-btn" @click="sendResetLink">
            {{ $t('common.send') }}
          </el-button>
        </div>
      </div>
    </ValidationObserver>
  </el-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import ScrollDisableMixin from '@/dependencies/mixins/scroll-disabler'
import ServErrorResetMixin from '@/dependencies/mixins/server-errors-reset'
import Input from '@/components/Common/Input'

export default {
  name: 'PasswordRecoveryModal',
  components: {
    Input,
    ValidationObserver
  },
  mixins: [ServErrorResetMixin, ScrollDisableMixin],
  data: () => ({
    email: null,
    show: false
  }),
  mounted() {
    this.$bus.$on('recoveryModal', () => this.toggleRecoveryModal())
  },
  beforeDestroy() {
    this.$bus.$off('recoveryModal')
  },
  methods: {
    ...mapActions({
      setError: 'common/SET_ERROR_MESSAGE'
    }),
    toggleRecoveryModal() {
      this.show = !this.show
    },
    async sendResetLink() {
      const isValid = await this.$refs.observer.validate()
      if (!isValid) return

      try {
        await this.$api.AuthApi.passwordRecovery({
          email: this.email
        })
        await Promise.all([
          this.$bus.$emit('success', 'recovery-email-sent'),
          (this.show = false)
        ])
      } catch (e) {
        await this.setError({ recovery: e })
        // eslint-disable-next-line no-console
        console.log(e)
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '~assets/css/variables';

.form-header {
  height: 50px;
  width: 100%;
  font-size: 18px;
  font-weight: 300;
  color: white;
  background-color: $primary;
  padding: 13px 10% 13px 13px;
  text-transform: uppercase;
  text-align: center;

  span {
    line-height: 22px;
  }
}

.form {
  padding: 13px 13px 13px 48px;

  &-line {
    position: relative;
    margin-bottom: 15px;

    i {
      font-size: 24px;
      color: $primary;
      position: absolute;
      left: -35px;
      top: 22px;
    }
  }

  .buttons-wrapper {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .form-header {
    span {
      font-size: 22px;
    }
  }

  .form {
    .buttons-wrapper {
      text-align: unset;
      padding-left: 15%;
    }
  }
}
</style>
