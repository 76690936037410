<template>
  <div id="footer" class="footer">
    <div class="custom-container mobile-custom-container">
      <div class="footer-top flex-column flex-md-row">
        <div class="d-flex flex-column flex-md-row contacts-container">
          <div class="soc-icons-mobile">
            <a :href="socLinks.fb" target="_blank" class="soc-btn" />
            <a :href="socLinks.insta" target="_blank" class="soc-btn" />
          </div>
          <div class="contacts-block">
            <p class="footer-header">
              {{ $t('common.contacts') }}
            </p>
            <p class="d-flex py-2">
              <i class="footer-icon icon-home" />
              <span>
                {{ address }}
              </span>
            </p>
            <p class="d-flex py-2">
              <i class="footer-icon icon-phone" />
              <a :href="`tel:${phone}`" class="phoneto">{{ phone }}</a>
            </p>
            <p class="d-flex py-2">
              <i class="footer-icon icon-email" />
              <a :href="`mailto:${email}`" class="mailto"> {{ email }} </a>
            </p>
          </div>
          <div class="client-support">
            <h2 class="footer-header">
              {{ $t('common.clients_support') }}
            </h2>
            <p class="d-flex py-2">
              <i class="footer-icon icon-calendar-footer-f" />
              <span>{{ $t('common.work_hours') }}</span>
            </p>
            <div v-if="support">
              <p class="d-flex py-2">
                <i class="footer-icon icon-phone" />
                <a :href="`tel:${support.first}`" class="phoneto">{{
                  support.first
                }}</a>
              </p>
              <p class="d-flex py-2">
                <i class="footer-icon icon-phone" />
                <a :href="`tel:${support.second}`" class="phoneto">{{
                  support.second
                }}</a>
              </p>
            </div>
          </div>
        </div>
        <div class="social-buttons">
          <p class="footer-header">
            {{ $t('common.we_in_social_networks') }}
            :
          </p>
          <div class="d-flex flex-md-column">
            <a
              :href="socLinks.insta"
              target="_blank"
              class="social-button instagram-button mb-md-2"
            />
            <a
              :href="socLinks.fb"
              target="_blank"
              class="social-button facebook-button"
            />
          </div>
        </div>
      </div>
      <div class="footer-bottom gray">
        <div class="popular-requests">
          <span class="fz-14 popular">{{ $t('common.popular_queries') }}:</span>
          <template v-for="el in cities">
            <button
              :key="el.id"
              class="popular-queries"
              @click="goToSearchPage(el)"
            >
              {{ $t('common.trainers_in') }} {{ cityNameInflect(el) }}
            </button>
          </template>
        </div>
        <h2 class="fz-18 fw6 pt-4 pb-3">
          {{ $t('common.trainer_search_in') }} {{ $t(country) }}
        </h2>
        <div>
          <p>
            {{ $t('common.about_site') }}
            <br />
            {{ $t('common.about_your_training') }}
            <br />
            {{ $t('common.about_personal_trainer') }}
          </p>
        </div>
      </div>
      <div class="bottom-pages">
        <nuxt-link
          :to="
            localePath({
              name: 'location-privacy',
              params: {
                location: city
              }
            })
          "
          class="privacy-policy"
          >{{ $t('common.privacy_policy') }}</nuxt-link
        >
        <p>{{ copyrightInfo }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PopularCitiesMixin from '@/dependencies/mixins/popular-cities'
import CityNameInflectorMixin from '@/dependencies/mixins/cityname-inflector'

export default {
  name: 'Footer',
  mixins: [PopularCitiesMixin, CityNameInflectorMixin],
  computed: {
    ...mapGetters({
      cityDomain: 'seo/getSelectedCity',
      city: 'location/getUrlKey',
      info: 'user/getFooterInfo'
    }),
    domain() {
      return this.cityDomain?.domainTitle
    },
    currentYear() {
      return new Date().getFullYear()
    },
    copyrightInfo() {
      return `Copyright © ${this.domain} – ${this.currentYear}`
    },
    socLinks() {
      return this.info?.SOCIAL_LINKS || {}
    },
    country() {
      return this.info?.COUNTRY_NAME || ''
    },
    email() {
      return this.info?.EMAIL || ''
    },
    phone() {
      return this.info?.PHONE || ''
    },
    support() {
      return this.info?.SUPPORT || ''
    },
    addressLang() {
      return this.info?.ADDRESS || ''
    },
    address() {
      return this.addressLang ? this.$t(`address.${this.addressLang}`) : ''
    }
  },

  methods: {
    ...mapActions({
      setCityName: 'location/SET_CURRENT_CITY_NAME',
      clearBeforeLocationChange: 'search/CLEAR_BEFORE_LOCATION_CHANGE',
      setLocation: 'search/SET_LOCATION'
    }),

    async goToSearchPage(_city) {
      await Promise.all([
        this.setCityName(_city.alternate_name),
        this.clearBeforeLocationChange(),
        this.setLocation(_city.id)
      ])

      const matchURL = this.$route.name.split('___')[0]

      if (matchURL === 'location-search-trainers-all') {
        window.scrollTo({
          top: 0,
          behavior: 'auto'
        })
      }

      return this.$router.push(
        this.localePath({
          name: 'location-search-trainers-all',
          params: {
            location: _city.url_key,
            0: ''
          }
        })
      )
    }
  }
}
</script>

<style scoped lang="scss">
@import '~assets/css/variables';

.footer {
  width: 100%;
  background-color: $dark-bg;

  .bottom-pages {
    a,
    p {
      color: #ffffff;
    }
  }

  span,
  .mailto {
    font-size: 12px;
  }

  .mailto,
  .phoneto {
    color: #ffffff;
  }

  .contacts-block {
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 210px;

    p {
      align-items: center;
    }

    p.d-flex {
      &:nth-of-type(3) {
        order: 3;
      }
    }
  }

  .contacts-container {
    position: relative;
  }

  .soc-icons-mobile {
    width: 70px;
    position: absolute;
    top: 10px;
    right: 0;
    display: flex;
    justify-content: space-between;

    a {
      display: inline-block;
      width: 32px;
      height: 32px;

      &:first-child {
        background-image: url('~assets/img/facebook-footer-icon-1.png');
      }
      &:last-child {
        background-image: url('~assets/img/instagram-footer-icon.png');
      }
    }
  }

  &-header {
    font-size: 16px;
    font-weight: 600;
    margin: 10px 0;
  }

  &-top {
    display: flex;
    justify-content: space-between;
    padding: 20px 0 10px 0;
    width: 100%;
    color: white;
    font-size: 16px;
  }

  &-bottom {
    padding: 30px 0 20px 0;
    line-height: 18px;

    .popular {
      display: block;
      font-size: 14px;
    }
  }

  &-icon {
    display: block;
    width: 14px;
    max-width: 14px;
    height: 18px;
    margin-right: 7px;
    background: no-repeat center;
  }

  .social-buttons {
    display: none;
  }
}

.social-button {
  display: block;
  min-width: 130px;
  max-width: 142px;
  height: 40px;
  background: no-repeat center;
}

.popular-queries {
  padding: 0 2px;
  text-decoration: underline dashed;
  color: $gray;
  &:hover {
    color: white;
  }
}

.footer-additional {
  display: flex;
  justify-content: flex-end;
  color: white;

  .privacy-policy {
    color: white;
    &:hover {
      text-decoration: underline dashed;
    }
  }
}

.footer-icon {
  font-size: 14px;
  line-height: 16px;
  color: $input-gray;
}

@media (max-width: 767px) {
  .mobile-custom-container {
    padding: 0 10px 20px 10px;
  }
}

@media (min-width: 768px) {
  .footer {
    span,
    .mailto {
      font-size: 16px;

      br {
        display: block;
      }
    }

    .client-support {
      display: block;
    }

    &-header {
      margin-top: 0;
      margin-bottom: 30px;
      font-size: 18px;
      font-weight: 600;
    }

    &-top {
      padding-top: 30px;
      border-bottom: 1px solid #5c5c5c;
    }

    &-bottom {
      .popular {
        display: inline;
      }
    }

    .contacts-block {
      margin-right: 100px;

      p.d-flex {
        &:nth-of-type(3) {
          order: unset;
        }
      }
    }

    .soc-icons-mobile {
      display: none;
    }

    .social-buttons {
      display: block;
    }

    .bottom-mobile {
      display: none;
    }

    .bottom-pages {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 50px;

      a {
        margin-right: 15px;

        &:hover {
          text-decoration: underline;
          text-decoration-style: dashed;
        }
      }
    }
  }

  .social-button {
    min-width: 207px;
    max-width: 207px;
  }

  .instagram-button {
    background-image: url('~assets/img/instagram_inactive.png');
    transition: 0.2s linear;

    &:hover {
      background-image: url('~assets/img/instagram_active.png');
      transition: 0.2s linear;
    }
  }

  .facebook-button {
    background-image: url('~assets/img/facebook_inactive.png');
    transition: 0.2s linear;

    &:hover {
      background-image: url('~assets/img/facebook_active.png');
      transition: 0.2s linear;
    }
  }
}
</style>
