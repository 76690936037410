import Vue from 'vue'
import Element from 'element-ui'
import locale from 'element-ui/lib/locale'
import ru from 'element-ui/lib/locale/lang/ru-RU'
import en from 'element-ui/lib/locale/lang/en'
import ua from 'element-ui/lib/locale/lang/ua'
import lt from 'element-ui/lib/locale/lang/lt'
import lv from 'element-ui/lib/locale/lang/lv'
import ee from 'element-ui/lib/locale/lang/ee'
import pl from 'element-ui/lib/locale/lang/pl'
import es from 'element-ui/lib/locale/lang/es'

Vue.use(Element)

const setLocale = (_lang) => {
  switch (_lang) {
    case 'ru': {
      return locale.use(ru)
    }
    case 'en': {
      return locale.use(en)
    }
    case 'uk': {
      return locale.use(ua)
    }
    case 'lt': {
      return locale.use(lt)
    }
    case 'lv': {
      return locale.use(lv)
    }
    case 'et': {
      return locale.use(ee)
    }
    case 'pl': {
      return locale.use(pl)
    }
    case 'es': {
      return locale.use(es)
    }
  }
}

export default function({ app }) {
  const locale = app.i18n.locale

  setLocale(locale)

  app.i18n.beforeLanguageSwitch = (oldLocale, newLocale) => {
    setLocale(newLocale)
  }
}
