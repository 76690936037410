export default class SubwayMapSelector {
  static GetMap(_id) {
    switch (_id) {
      case 703448:
        return {
          url: require(`~/assets/img/subway-maps/subway_kiev.png`),
          class: 'kiev'
        }
      case 706483:
        return {
          url: require(`~/assets/img/subway-maps/subway_harkov.png`),
          class: 'harkov'
        }
      case 709930:
        return {
          url: require(`~/assets/img/subway-maps/subway_dnepr.png`),
          class: 'dnepr'
        }
      case 756135:
        return {
          url: require(`~/assets/img/subway-maps/subway_warszawa.png`),
          class: 'warsaw'
        }
      default:
        return null
    }
  }
}
