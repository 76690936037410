import AuthApi from '@/api/auth'
import UserApi from '@/api/user'
import SportApi from '@/api/sports'
import LocationApi from '@/api/location'
import DictApi from '@/api/dict'
import PublicApi from '@/api/public'

export default class ApiService {
  constructor({ $axios }) {
    this.AuthApi = new AuthApi($axios)
    this.UserApi = new UserApi($axios)
    this.SportApi = new SportApi($axios)
    this.LocationApi = new LocationApi($axios)
    this.DictApi = new DictApi($axios)
    this.PublicApi = new PublicApi($axios)
  }
}
