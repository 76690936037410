import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      popularCities: 'location/getPopularCities'
    }),
    capital() {
      return this.popularCities?.capital
    },
    // Remove cities without alternate_name
    filteredCities() {
      return this.popularCities?.cities.filter((el) => el.alternate_name)
    },
    cities() {
      return this.popularCities ? [...this.filteredCities, this.capital] : []
    }
  }
}
